import React from "react";
import logo from "../../assets/icons/logo.png";
import aboutUsImage from "../../assets/icons/aboutUs.png";

const AboutUs = () => {
  return (
    <div>
      <div id="about-us-section">
        <div id="about-us-content-section">
          <div id="about-us-content-container">
            <div id="about-us-heading">About Us</div>
            <div id="about-us-text">
              Users can now support content creators by sending hearts using the
              Little Hearts extension. It’s a way of thanking them for making
              great content. Once the user has reached a threshold level of
              hearts sent, the user is asked to make the payment for the no. of
              hearts given. The payment so received is appropriated amongst the
              content creators based on the no. of hearts assigned by the user.
              Thus, the Little Hearts application facilitates as a bridge
              between creators of content & their audience, through which the
              audience can support the creators' cause
            </div>
          </div>
        </div>
        <div id="about-us-image-section">
          <div id="about-us-image-container">
            <img src={aboutUsImage} id="about-us-image" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
