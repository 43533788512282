const locations = [
  {
    TZ_code: 1,
    timeZoneInPC: "(UTC-12:00) International Date Line West",
    timeZoneInApp: "Etc/GMT+12",
  },
  {
    TZ_code: 2,
    timeZoneInPC: "(UTC-11:00) Coordinated Universal Time-11",
    timeZoneInApp: "Etc/GMT+11",
  },
  {
    TZ_code: 3,
    timeZoneInPC: "(UTC-10:00) Aleautin Islands",
    timeZoneInApp: "America/Adak",
  },
  {
    TZ_code: 4,
    timeZoneInPC: "(UTC-10:00) Hawaii",
    timeZoneInApp: "Pacific/Honolulu",
  },
  {
    TZ_code: 5,
    timeZoneInPC: "(UTC-09:30) Marquesas Islands",
    timeZoneInApp: "Pacific/Marquesas",
  },
  {
    TZ_code: 6,
    timeZoneInPC: "(UTC-09:00) Alaska",
    timeZoneInApp: "America/Anchorage",
  },
  {
    TZ_code: 7,
    timeZoneInPC: "(UTC-09:00) Coordinated Universal Time-09",
    timeZoneInApp: "Etc/GMT+9",
  },
  {
    TZ_code: 8,
    timeZoneInPC: "(UTC-08:00) Baja California",
    timeZoneInApp: "America/Tijuana",
  },
  {
    TZ_code: 9,
    timeZoneInPC: "(UTC-08:00) Coordinated Universal Time-09",
    timeZoneInApp: "Etc/GMT+8",
  },
  {
    TZ_code: 10,
    timeZoneInPC: "(UTC-08:00) Pacific Time (US & Canada)",
    timeZoneInApp: "America/Los_Angeles",
  },
  {
    TZ_code: 11,
    timeZoneInPC: "(UTC-07:00) Arizona",
    timeZoneInApp: "America/Phoenix",
  },
  {
    TZ_code: 12,
    timeZoneInPC: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    timeZoneInApp: "America/Chihuahua",
  },
  {
    TZ_code: 13,
    timeZoneInPC: "(UTC-07:00) Mountain Time (US & Canada)",
    timeZoneInApp: "America/Denver",
  },
  {
    TZ_code: 14,
    timeZoneInPC: "(UTC-07:00) Yukon",
    timeZoneInApp: "America/Whitehorse",
  },
  {
    TZ_code: 15,
    timeZoneInPC: "(UTC-06:00) Central America",
    timeZoneInApp: "America/Guatemala",
  },
  {
    TZ_code: 16,
    timeZoneInPC: "(UTC-06:00) Central Time (US & Canada)",
    timeZoneInApp: "America/Chicago",
  },
  {
    TZ_code: 17,
    timeZoneInPC: "(UTC-06:00) Easter Island",
    timeZoneInApp: "Pacific/Easter",
  },
  {
    TZ_code: 18,
    timeZoneInPC: "(UTC-06:00) Guadalajara, Mexico City, Monterray",
    timeZoneInApp: "America/Mexico_City",
  },
  {
    TZ_code: 19,
    timeZoneInPC: "(UTC-06:00) Saskatchewan",
    timeZoneInApp: "America/Regina",
  },
  {
    TZ_code: 20,
    timeZoneInPC: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
    timeZoneInApp: "America/Bogota",
  },
  {
    TZ_code: 21,
    timeZoneInPC: "(UTC-05:00) Chetumal",
    timeZoneInApp: "America/Cancun",
  },
  {
    TZ_code: 22,
    timeZoneInPC: "(UTC-05:00) Eastern Time (US & Canada)",
    timeZoneInApp: "America/New_York",
  },
  {
    TZ_code: 23,
    timeZoneInPC: "(UTC-05:00) Haiti",
    timeZoneInApp: "America/Port-au-Prince",
  },
  {
    TZ_code: 24,
    timeZoneInPC: "(UTC-05:00) Havana",
    timeZoneInApp: "America/Havana",
  },
  {
    TZ_code: 25,
    timeZoneInPC: "(UTC-05:00) Indiana(East)",
    timeZoneInApp: "America/Indianapolis",
  },
  {
    TZ_code: 26,
    timeZoneInPC: "(UTC-05:00) Turks And Caicos",
    timeZoneInApp: "America/Grand_Turk",
  },
  {
    TZ_code: 27,
    timeZoneInPC: "(UTC-04:00) Asuncion",
    timeZoneInApp: "America/Asuncion",
  },
  {
    TZ_code: 28,
    timeZoneInPC: "(UTC-04:00) Atlantic Time (Canada)",
    timeZoneInApp: "America/Halifax",
  },
  {
    TZ_code: 29,
    timeZoneInPC: "(UTC-04:00) Caracas",
    timeZoneInApp: "America/Caracas",
  },
  {
    TZ_code: 30,
    timeZoneInPC: "(UTC-04:00) Cuiaba",
    timeZoneInApp: "America/Cuiaba",
  },
  {
    TZ_code: 31,
    timeZoneInPC: "(UTC-04:00) Geogetown, La Paz, Manaus, San Juan",
    timeZoneInApp: "America/La_Paz",
  },
  {
    TZ_code: 32,
    timeZoneInPC: "(UTC-04:00) Santiago",
    timeZoneInApp: "America/Santiago",
  },
  {
    TZ_code: 33,
    timeZoneInPC: "(UTC-03:30) Newfoundland",
    timeZoneInApp: "America/St_Johns",
  },
  {
    TZ_code: 34,
    timeZoneInPC: "(UTC-03:00) Araguaina",
    timeZoneInApp: "America/Araguaina",
  },
  {
    TZ_code: 35,
    timeZoneInPC: "(UTC-03:00) Brasilia",
    timeZoneInApp: "America/Sao_Paulo",
  },
  {
    TZ_code: 36,
    timeZoneInPC: "(UTC-03:00) Cayenne, Foraleza",
    timeZoneInApp: "America/Cayenne",
  },
  {
    TZ_code: 37,
    timeZoneInPC: "(UTC-03:00) City of Buenos Aires",
    timeZoneInApp: "America/Buenos_Aires",
  },
  {
    TZ_code: 38,
    timeZoneInPC: "(UTC-03:00) Greenland",
    timeZoneInApp: "America/Godthab",
  },
  {
    TZ_code: 39,
    timeZoneInPC: "(UTC-03:00) Montevideo",
    timeZoneInApp: "America/Montevideo",
  },
  {
    TZ_code: 40,
    timeZoneInPC: "(UTC-03:00) Punta Arenas",
    timeZoneInApp: "America/Punta_Arenas",
  },
  {
    TZ_code: 41,
    timeZoneInPC: "(UTC-03:00) Saint Pierre and Miquelon",
    timeZoneInApp: "America/Miquelon",
  },
  {
    TZ_code: 42,
    timeZoneInPC: "(UTC-03:00) Salvador",
    timeZoneInApp: "America/Bahia",
  },
  {
    TZ_code: 43,
    timeZoneInPC: "(UTC-02:00) Coordinate Universal Time-02",
    timeZoneInApp: "Etc/GMT+2",
  },
  {
    TZ_code: 44,
    timeZoneInPC: "(UTC-01:00) Azores",
    timeZoneInApp: "Atlantic/Azores",
  },
  {
    TZ_code: 45,
    timeZoneInPC: "(UTC-01:00) Cabo Verde Is.",
    timeZoneInApp: "Atlantic/Cape_Verde",
  },
  {
    TZ_code: 46,
    timeZoneInPC: "(UTC) Coordinated Universal Time",
    timeZoneInApp: "UTC",
  },
  {
    TZ_code: 47,
    timeZoneInPC: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    timeZoneInApp: "Europe/London",
  },
  {
    TZ_code: 48,
    timeZoneInPC: "(UTC+00:00) Monrovia, Reykjavik",
    timeZoneInApp: "Atlantic/Reykjavik",
  },
  {
    TZ_code: 49,
    timeZoneInPC: "(UTC+00:00) Sao Tome",
    timeZoneInApp: "Africa/Sao_Tome",
  },
  {
    TZ_code: 50,
    timeZoneInPC: "(UTC+01:00) Casablanca",
    timeZoneInApp: "Africa/Casablanca",
  },
  {
    TZ_code: 51,
    timeZoneInPC: "(UTC+01:00) Amsterdamn, Berlin, Rome, Stockholm, Vienna",
    timeZoneInApp: "Europe/Berlin",
  },
  {
    TZ_code: 52,
    timeZoneInPC:
      "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    timeZoneInApp: "Europe/Budapest",
  },
  {
    TZ_code: 53,
    timeZoneInPC: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    timeZoneInApp: "Europe/Paris",
  },
  {
    TZ_code: 54,
    timeZoneInPC: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    timeZoneInApp: "Europe/Warsaw",
  },
  {
    TZ_code: 55,
    timeZoneInPC: "(UTC+01:00) West Central Africa",
    timeZoneInApp: "Africa/Lagos",
  },
  {
    TZ_code: 56,
    timeZoneInPC: "(UTC+02:00) Amman",
    timeZoneInApp: "Asia/Amman",
  },
  {
    TZ_code: 57,
    timeZoneInPC: "(UTC+02:00) Athens, Bucharest",
    timeZoneInApp: "Europe/Bucharest",
  },
  {
    TZ_code: 58,
    timeZoneInPC: "(UTC+02:00) Beirut",
    timeZoneInApp: "Asia/Beirut",
  },
  {
    TZ_code: 59,
    timeZoneInPC: "(UTC+02:00) Cairo",
    timeZoneInApp: "Africa/Cairo",
  },
  {
    TZ_code: 60,
    timeZoneInPC: "(UTC+02:00) Chisinau",
    timeZoneInApp: "Europe/Chisinau",
  },
  {
    TZ_code: 61,
    timeZoneInPC: "(UTC+02:00) Damascus",
    timeZoneInApp: "Asia/Damascus",
  },
  {
    TZ_code: 62,
    timeZoneInPC: "(UTC+02:00) Gaza, Hebron",
    timeZoneInApp: "Asia/Hebron",
  },
  {
    TZ_code: 63,
    timeZoneInPC: "(UTC+02:00) Harare, Pretoria",
    timeZoneInApp: "Africa/Johannesburg",
  },
  {
    TZ_code: 64,
    timeZoneInPC: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Talinn, Vilnius",
    timeZoneInApp: "Europe/Kiev",
  },
  {
    TZ_code: 65,
    timeZoneInPC: "(UTC+02:00) Jerusalem",
    timeZoneInApp: "Asia/Jerusalem",
  },
  {
    TZ_code: 66,
    timeZoneInPC: "(UTC+02:00) Juba",
    timeZoneInApp: "Africa/Juba",
  },
  {
    TZ_code: 67,
    timeZoneInPC: "(UTC+02:00) Kaliningrad",
    timeZoneInApp: "Europe/Kaliningrad",
  },
  {
    TZ_code: 68,
    timeZoneInPC: "(UTC+02:00) Khartoum",
    timeZoneInApp: "Africa/Khartoum",
  },
  {
    TZ_code: 69,
    timeZoneInPC: "(UTC+02:00) Tripoli",
    timeZoneInApp: "Africa/Tripoli",
  },
  {
    TZ_code: 70,
    timeZoneInPC: "(UTC+02:00) Windhoek",
    timeZoneInApp: "Africa/Windhoek",
  },
  {
    TZ_code: 71,
    timeZoneInPC: "(UTC+03:00) Baghdad",
    timeZoneInApp: "Asia/Baghdad",
  },
  {
    TZ_code: 72,
    timeZoneInPC: "(UTC+03:00) Istanbul",
    timeZoneInApp: "Europe/Istanbul",
  },
  {
    TZ_code: 73,
    timeZoneInPC: "(UTC+03:00) Kuwait, Riyadh",
    timeZoneInApp: "Europe/Istanbul",
  },
  {
    TZ_code: 74,
    timeZoneInPC: "(UTC+03:00) Minsk",
    timeZoneInApp: "Europe/Minsk",
  },
  {
    TZ_code: 75,
    timeZoneInPC: "(UTC+03:00) Moscow. St. Petersburg",
    timeZoneInApp: "Europe/Moscow",
  },
  {
    TZ_code: 76,
    timeZoneInPC: "(UTC+03:00) Nairobi",
    timeZoneInApp: "Africa/Nairobi",
  },
  {
    TZ_code: 77,
    timeZoneInPC: "(UTC+03:00) Volgograd",
    timeZoneInApp: "Europe/Volgograd",
  },
  {
    TZ_code: 78,
    timeZoneInPC: "(UTC+03:30) Tehran",
    timeZoneInApp: "Asia/Tehran",
  },
  {
    TZ_code: 79,
    timeZoneInPC: "(UTC+04:00) Abu Dhabi, Muscat",
    timeZoneInApp: "Asia/Dubai",
  },
  {
    TZ_code: 80,
    timeZoneInPC: "(UTC+04:00) Astrakhan, Ulyanovsk",
    timeZoneInApp: "Europe/Astrakhan",
  },
  {
    TZ_code: 81,
    timeZoneInPC: "(UTC+04:00) Baku",
    timeZoneInApp: "Asia/Baku",
  },
  {
    TZ_code: 82,
    timeZoneInPC: "(UTC+04:00) Izhevsk, Samara",
    timeZoneInApp: "Europe/Samara",
  },
  {
    TZ_code: 83,
    timeZoneInPC: "(UTC+04:00) Port Louis",
    timeZoneInApp: "Indian/Mauritius",
  },
  {
    TZ_code: 84,
    timeZoneInPC: "(UTC+04:00) Saratov",
    timeZoneInApp: "Europe/Saratov",
  },
  {
    TZ_code: 85,
    timeZoneInPC: "(UTC+04:00) Tbilisi",
    timeZoneInApp: "Asia/Tbilisi",
  },
  {
    TZ_code: 86,
    timeZoneInPC: "(UTC+04:00) Yerevan",
    timeZoneInApp: "Asia/Yerevan",
  },
  {
    TZ_code: 87,
    timeZoneInPC: "(UTC+04:30) Kabul",
    timeZoneInApp: "Asia/Kabul",
  },
  {
    TZ_code: 88,
    timeZoneInPC: "(UTC+05:00) Ashabat, Tashkent",
    timeZoneInApp: "Asia/Tashkent",
  },
  {
    TZ_code: 89,
    timeZoneInPC: "(UTC+05:00) Ekaterinburg",
    timeZoneInApp: "Asia/Yekaterinburg",
  },
  {
    TZ_code: 90,
    timeZoneInPC: "(UTC+05:00) Islamabad Karachi",
    timeZoneInApp: "Asia/Karachi",
  },
  {
    TZ_code: 91,
    timeZoneInPC: "(UTC+05:00) Qyzylorda",
    timeZoneInApp: "Asia/Qyzylorda",
  },
  {
    TZ_code: 92,
    timeZoneInPC: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    timeZoneInApp: "Asia/Calcutta",
  },
  {
    TZ_code: 93,
    timeZoneInPC: "(UTC+05:00) Sri Jayawardenepura",
    timeZoneInApp: "Asia/Colombo",
  },
  {
    TZ_code: 94,
    timeZoneInPC: "(UTC+05:45) Kathmandu",
    timeZoneInApp: "Asia/Katmandu",
  },
  {
    TZ_code: 95,
    timeZoneInPC: "(UTC+06:00) Astana",
    timeZoneInApp: "Asia/Almaty",
  },
  {
    TZ_code: 96,
    timeZoneInPC: "(UTC+06:00) Dhaka",
    timeZoneInApp: "Asia/Dhaka",
  },
  {
    TZ_code: 97,
    timeZoneInPC: "(UTC+06:00) Omsk",
    timeZoneInApp: "Asia/Omsk",
  },
  {
    TZ_code: 98,
    timeZoneInPC: "(UTC+06:30) Yangon (Rangoon)",
    timeZoneInApp: "Asia/Rangoon",
  },
  {
    TZ_code: 99,
    timeZoneInPC: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    timeZoneInApp: "Asia/Bangkok",
  },
  {
    TZ_code: 100,
    timeZoneInPC: "(UTC+07:00) Bernaul, Forno-Altaysk",
    timeZoneInApp: "Asia/Barnaul",
  },
  {
    TZ_code: 101,
    timeZoneInPC: "(UTC+07:00) Hovd",
    timeZoneInApp: "Asia/Hovd",
  },
  {
    TZ_code: 102,
    timeZoneInPC: "(UTC+07:00) Krasnoyarsk",
    timeZoneInApp: "Asia/Krasnoyarsk",
  },
  {
    TZ_code: 103,
    timeZoneInPC: "(UTC+07:00) Novosibirsk",
    timeZoneInApp: "Asia/Novosibirsk",
  },
  {
    TZ_code: 104,
    timeZoneInPC: "(UTC+07:00) Tomsk",
    timeZoneInApp: "Asia/Tomsk",
  },
  {
    TZ_code: 105,
    timeZoneInPC: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    timeZoneInApp: "Asia/Shanghai",
  },
  {
    TZ_code: 106,
    timeZoneInPC: "(UTC+08:00) Irkutsk",
    timeZoneInApp: "Asia/Irkutsk",
  },
  {
    TZ_code: 107,
    timeZoneInPC: "(UTC+08:00) Kuala Lumpur, Singapore",
    timeZoneInApp: "Asia/Singapore",
  },
  {
    TZ_code: 108,
    timeZoneInPC: "(UTC+08:00) Perth",
    timeZoneInApp: "Australia/Perth",
  },
  {
    TZ_code: 109,
    timeZoneInPC: "(UTC+08:00) Taipei",
    timeZoneInApp: "Asia/Taipei",
  },
  {
    TZ_code: 110,
    timeZoneInPC: "(UTC+08:00) UlaanBaatar",
    timeZoneInApp: "Asia/Ulaanbaatar",
  },
  {
    TZ_code: 111,
    timeZoneInPC: "(UTC+08:45) Eucla",
    timeZoneInApp: "Australia/Eucla",
  },
  {
    TZ_code: 112,
    timeZoneInPC: "(UTC+09:00) Chita",
    timeZoneInApp: "Asia/Chita",
  },
  {
    TZ_code: 113,
    timeZoneInPC: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    timeZoneInApp: "Asia/Tokyo",
  },
  {
    TZ_code: 114,
    timeZoneInPC: "(UTC+09:00) Pyongyang",
    timeZoneInApp: "Asia/Pyongyang",
  },
  {
    TZ_code: 115,
    timeZoneInPC: "(UTC+09:00) Seoul",
    timeZoneInApp: "Asia/Seoul",
  },
  {
    TZ_code: 116,
    timeZoneInPC: "(UTC+09:00) Yakutsk",
    timeZoneInApp: "Asia/Yakutsk",
  },
  {
    TZ_code: 117,
    timeZoneInPC: "(UTC+09:30) Adelaide",
    timeZoneInApp: "Australia/Adelaide",
  },
  {
    TZ_code: 118,
    timeZoneInPC: "(UTC+09:30) Darwin",
    timeZoneInApp: "Australia/Darwin",
  },
  {
    TZ_code: 119,
    timeZoneInPC: "(UTC+10:00) Brisbane",
    timeZoneInApp: "Australia/Brisbane",
  },
  {
    TZ_code: 120,
    timeZoneInPC: "(UTC+10:00) Canberra, Melbourne, Sydney",
    timeZoneInApp: "Australia/Sydney",
  },
  {
    TZ_code: 121,
    timeZoneInPC: "(UTC+10:00) Guam, Port Moresby",
    timeZoneInApp: "Pacific/Port_Moresby",
  },
  {
    TZ_code: 122,
    timeZoneInPC: "(UTC+10:00) Hobart",
    timeZoneInApp: "Australia/Hobart",
  },
  {
    TZ_code: 123,
    timeZoneInPC: "(UTC+10:00) Vladivostok",
    timeZoneInApp: "Asia/Vladivostok",
  },
  {
    TZ_code: 124,
    timeZoneInPC: "(UTC+10:30) Lord Howe Island",
    timeZoneInApp: "Australia/Lord_Howe",
  },
  {
    TZ_code: 125,
    timeZoneInPC: "(UTC+11:00) Bougainvilla Island",
    timeZoneInApp: "Pacific/Bougainville",
  },
  {
    TZ_code: 126,
    timeZoneInPC: "(UTC+11:00) Chokurdakh",
    timeZoneInApp: "Asia/Srednekolymsk",
  },
  {
    TZ_code: 127,
    timeZoneInPC: "(UTC+11:00) Magadon",
    timeZoneInApp: "Asia/Magadan",
  },
  {
    TZ_code: 128,
    timeZoneInPC: "(UTC+11:00) Norfolk Island",
    timeZoneInApp: "Pacific/Norfolk",
  },
  {
    TZ_code: 129,
    timeZoneInPC: "(UTC+11:00) Sakhalin",
    timeZoneInApp: "Asia/Sakhalin",
  },
  {
    TZ_code: 130,
    timeZoneInPC: "(UTC+11:00) Solomon Is., New Caledonia",
    timeZoneInApp: "Pacific/Guadalcanal",
  },
  {
    TZ_code: 131,
    timeZoneInPC: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
    timeZoneInApp: "Asia/Kamchatka",
  },
  {
    TZ_code: 132,
    timeZoneInPC: "(UTC+12:00) Auckland, Wellington",
    timeZoneInApp: "Pacific/Auckland",
  },
  {
    TZ_code: 133,
    timeZoneInPC: "(UTC+12:00) Coordinate Universal Time+12",
    timeZoneInApp: "Etc/GMT-12",
  },
  {
    TZ_code: 134,
    timeZoneInPC: "(UTC+12:00) Fiji",
    timeZoneInApp: "Pacific/Fiji",
  },
  {
    TZ_code: 135,
    timeZoneInPC: "(UTC+12:45) Chatham Islands",
    timeZoneInApp: "Pacific/Chatham",
  },
  {
    TZ_code: 136,
    timeZoneInPC: "(UTC+13:00) Coordinate Universal Time+12",
    timeZoneInApp: "Etc/GMT-13",
  },
  {
    TZ_code: 137,
    timeZoneInPC: "(UTC+13:00) Nuku'alofa",
    timeZoneInApp: "Pacific/Tongatapu",
  },
  {
    TZ_code: 138,
    timeZoneInPC: "(UTC+13:00) Samoa",
    timeZoneInApp: "Pacific/Apia",
  },
  {
    TZ_code: 139,
    timeZoneInPC: "(UTC+14:00) Kiritimati Island",
    timeZoneInApp: "Pacific/Kiritimati",
  },
];

export default locations;
