/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import {
  contentVerificationAdmin
} from "../apiCalls/apiCalls";
import { read_cookie } from "sfcookies";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from "variables/charts.js";

import "../assets/manualCSS/dashboards.css";
function VerifyContent(props) {
  const [emailId, setEmailId] = useState("");
  const [website, setWebsite] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const user = read_cookie("userInfo");

  function handleSubmit() {
    if (!emailId && !website) {
      setErrorMessage("Please Enter Email Id and Website URL!");
      return;
    }
    if (!emailId) {
      setErrorMessage("Please Enter Email Id!");
      return;
    }
    if (!website) {
      setErrorMessage("Please Enter Website URL!");
      return;
    }
    setErrorMessage("");
    contentVerificationAdmin(emailId,website).then((data) => {
      if (data.error) {
        console.log("error", data.error);
        setErrorMessage("Somethings wrong with backend!");
      } else {
        if(data.msg)
          setErrorMessage(data.msg);
        else if(data.message)
          setErrorMessage(data.message);
      }
    });
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="8" md="12">
            <div class="mt-3">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Hey! Enter the Email Id and Website URL...
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Label for="totalcost" sm={5}>
                      <center>Email Id:</center>
                    </Label>
                    <Col sm={6}>
                      <Input
                        id="totalcost"
                        name="totalcost"
                        placeholder="name@email.com"
                        type="email"
                        onChange={(e)=>setEmailId(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Label for="totalcost" sm={5}>
                      <center>Website URL:</center>
                    </Label>
                    <Col sm={6}>
                      <Input
                        id="totalcost"
                        name="totalcost"
                        placeholder="something.com"
                        type="text"
                        onChange={(e)=>setWebsite(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row
                    className="justify-content-center"
                    style={{ marginTop: "10px" }}
                  >
                    <Col sm={4} md="auto">
                      <Button
                        size="sm"
                        className="btn-redeem-hearts"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                  <Row
                    className="justify-content-center"
                    style={{ marginTop: "10px" }}
                  >
                    {errorMessage ? errorMessage : ""}
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default VerifyContent;
