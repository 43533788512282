import React, { Component } from 'react'
import loading from '../assets/img/loader.gif'
const Loading=()=> {
    
        return (
            <div className='text-center'>
                <img style={{height: "52px"}} src={loading} alt="#" />
            </div>
        )
    }

export default Loading    

