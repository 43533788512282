/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import PrivateRoute from "./protectedRoutes/privateRoutes";
import SignUp from "components/Authentication/signUp";
import YoutubeVerification from "./verification/youtubeVerification";
import WebsiteVerification from "verification/websiteVerification";
import SignIn from "components/Authentication/signIn";
import { CookiesProvider } from "react-cookie";
import UserDashboard from "views/UserDashboard";
import ForgotPassword from "components/Authentication/forgotPassword";
import Home from "components/Home/Home";
import ResetPassword from "components/Authentication/resetPassword";
import VerifyEmail from "components/Authentication/VerifyEmail";

ReactDOM.render(
  // <CookiesProvider>
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <BrowserRouter>
        <Switch>
          {/* <Redirect from="/" to="/signin" /> */}
          {/* <CreatorRoute
            path="/admin"
            render={(props) => <AdminLayout {...props} />}
          /> */}
          {/* <PrivateRoute
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            /> */}
          <Route
            path="/verifyEmail"
            render={(props) => <VerifyEmail {...props} />}
          />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <PrivateRoute path="/signUp" exact component={SignUp} />
          <PrivateRoute path="/signIn" exact component={SignIn} />
          <PrivateRoute path="/home" exact component={Home} />
          <PrivateRoute
            path="/forgotPassword"
            exact
            component={ForgotPassword}
          />
          <PrivateRoute path="/resetPassword" exact component={ResetPassword} />
          {/* <Route path="/signUp" exact component={SignUp} /> */}
          {/* <Route path="/signIn" exact component={SignIn} /> */}

          <Route
            path="/youtubeverification"
            exact
            component={YoutubeVerification}
          />
          <Route
            path="/websiteverification"
            exact
            component={WebsiteVerification}
          />

          {/* <Route path="/dashboard" exact component={UserDashboard} /> */}
          {/* <Route path="/rtl" render={(props) => <RTLLayout {...props} />} /> */}
          {/* <Redirect from="/" to="/admin/dashboard" /> */}
          <Redirect from="/" to="/signIn" />
        </Switch>
      </BrowserRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>,
  // </CookiesProvider>,
  document.getElementById("root")
);
