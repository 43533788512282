import React, { useEffect, useState } from "react";
import { read_cookie, bake_cookie } from "sfcookies";
import { youtubeContentVerificationResponse } from "apiCalls/apiCalls";
import { useHistory } from "react-router";
import { updateGuestAcc } from "apiCalls/apiCalls";

const YoutubeVerification = () => {
  const user = read_cookie("userInfo");
  const token = read_cookie("userToken");

  const [verificationMsg, setVerificationMsg] = useState({
    msg: "Verification under process...",
  });
  const [classForMsg, setClassForMsg] = useState("text-primary");

  const history = useHistory();

  function findGetParameter(parameterName) {
    var result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });

    return result;
  }

  useEffect(() => {
    // console.log(window.location.href);
    const body = {
      code: findGetParameter("code"),
      user: user,
    };

    // console.log(findGetParameter("code"));

    youtubeContentVerificationResponse(token, body).then((data) => {
      if (data.error) {
        console.log("error", data.error);
      } else {
        // console.log(data);
        setVerificationMsg(data);

        if (data.msg === "No channel found") {
          setClassForMsg("text-danger");
        } else if (data.msg === "Content owner updated!") {
          let body = {
            id: user._id,
            user: { creatorAccount: true },
          };

          updateGuestAcc(body)
            .then((data) => {
              if (data.error) {
                console.log("Error", data.error);
              } else {
                bake_cookie("userInfo", data);

                console.log("Creator account value is changed");
              }
            })
            .catch(console.log("Creator account updated"));
          setClassForMsg("text-warning");
        } else if (
          data.msg ===
          "Channel verified! Your content is successfully linked with your account"
        ) {
          let body = {
            id: user._id,
            user: { creatorAccount: true },
          };

          updateGuestAcc(body)
            .then((data) => {
              if (data.error) {
                console.log("Error", data.error);
              } else {
                bake_cookie("userInfo", data);

                console.log("Creator account value is changed");
              }
            })
            .catch(console.log("Creator account updated"));

          setClassForMsg("text-success");
        }

        setTimeout(() => {
          history.push("/admin/contentverification");
        }, 5000);
      }
    });
  }, []);

  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ width: "100vw", height: "100vh" }}
      >
        <h1 className="text-white">Youtube Verification</h1>
        <p className={classForMsg}>{verificationMsg.msg}</p>
      </div>
    </>
  );
};

export default YoutubeVerification;
