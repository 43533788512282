import React, { useState, useEffect } from "react";
import { GiHearts } from "react-icons/gi";
import { FiRefreshCcw } from "react-icons/fi";
import { read_cookie, bake_cookie } from "sfcookies";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import {
  getContentCreatorDashboard,
  getUserDashboard,
  isSignedIn,
} from "../apiCalls/apiCalls";
import axios from "axios";
import { LittleHeartsAPI } from "backend";

const Activity = (props) => {
  const [contentsDisplay, setContentDisplay] = useState([]);
  const [refreshState, setRefreshState] = useState(true);
  const [totalHeartsPurchased, setTotalHeartsPurchased] = useState(0);
  const [totalHeartsSpent, setTotalHeartsSpent] = useState(0);
  const [totalHeartsAvailable, setTotalHeartsAvailable] = useState(0);
  const [payedHearts, setPayedHearts] = useState(0);
  const [pendingHearts, setPendingHearts] = useState(0);
  const [creatorName, setCreatorName] = useState("");
  // const [refreshState, setRefreshState] = useState(true);

  useEffect(async () => {
    let userInfo = await read_cookie("userInfo");
    console.log(userInfo._id);
    axios
      .get(`${LittleHeartsAPI}/userdata/${userInfo._id}`)
      .then((response) => {
        console.log("bruh", response);
        if (response.data.user != null) {
          console.log(response.data.user);
          setTotalHeartsAvailable(response.data.user.totalHeartsAvailable);
          setTotalHeartsPurchased(response.data.user.totalHeartsPurchased);
          setTotalHeartsSpent(response.data.user.totalHeartsSpent);
          setPayedHearts(response.data.user.payedhearts);
          setPendingHearts(response.data.user.pendinghearts);
        }
      });
  }, [refreshState]);
  useEffect(() => {
    const setContent = async () => {
      const token = await read_cookie("userToken");
      // console.log(Cookies.get("userToken"));
      getUserDashboard(token).then(async (data) => {
        if (data.error) {
          console.log("Error is", data.error);
        } else {
          let temp = data.topHeartsGivenContents;
          temp = temp.map(async (x) => {
            // console.log("xxxxxxxxxxxxxxxxx", x);
            if (x.paymentDone !== undefined && x.paymentDone === false) {
              const res = await axios.post(
                `${LittleHeartsAPI}/checkverified?url=${x.content}`
              );
              if (res.data.msg) {
                // console.log("resssssssssssssssssssssss", res);
                // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", x);
                x.paymentDone = false;
                x.creatorName = res.data.creatorName;
                console.log("Setting true", res.data.msg, x);
              } else {
                x.paymentDone = true;
              }
            } else {
              x.paymentDone = true;
            }
            return x;
          });
          temp = await Promise.all(temp);
          console.log(temp);
          console.log("calling");
          setContentDisplay(temp);
          console.log("contentttttttttttttttttttttttttt", temp);
        }
      });
    };
    setContent();
  }, [refreshState]);

  return (
    <div>
      <div class="mt-3">
        <div class="top-cards-row">
          <div class="top-cards-column">
            <img src={window.location.origin + "/icons/available.png"} alt="" />
            <div className="count-column">
              <div className="heart-count">{totalHeartsAvailable}</div>
              <div>Hearts Available</div>
            </div>
          </div>
          <div class="top-cards-column">
            <img src={window.location.origin + "/icons/Spent.png"} alt="" />
            <div className="count-column">
              <div className="heart-count">{totalHeartsSpent}</div>
              <div>Hearts Spent</div>
            </div>
          </div>
          <div class="top-cards-column">
            <img src={window.location.origin + "/icons/purchased.png"} alt="" />
            <div className="count-column">
              <div className="heart-count">{totalHeartsPurchased}</div>
              <div>Hearts Credited</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <Card className="card-custom">
          <CardHeader className="d-flex flex-row justify-content-between card-header">
            <CardTitle tag="h4" className="activity-card-title card-title">
              Hey {props.user.userName}! These are your activities
            </CardTitle>

            <h4
              style={{ cursor: "pointer" }}
              onClick={() => setRefreshState((refreshState) => !refreshState)}
            >
              <div className="refresh-container">
                <div className="refresh-icon-img-container">
                  <img
                    className="refresh-icon-img"
                    src={window.location.origin + "/icons/refresh (1).png"}
                    alt=""
                  />
                </div>
                <div className="refresh-msg">Refresh</div>
              </div>
              {/* <FiRefreshCcw /> */}
            </h4>
          </CardHeader>
          <div
            style={{
              width: "1200px",
              color: "rgba(255, 255, 255, 0.7)",
              marginLeft: "30px",
              marginTop: "0px",
            }}
          >
            * Creator on-boarded with Little Hearts
            {/* * Creator on-boarded with Little Hearts ^ Creator yet to on-board
            with Little Hearts */}
          </div>
          <CardBody>
            <Table id="activity-header">
              {/* className="tablesorter" responsive */}

              <thead className="text-primary">
                <tr id="activity-top-row">
                  <th className="text-center">Date</th>
                  <th className="text-center">Content</th>
                  {/* <th>Country</th> */}
                  {/* <th>City</th> */}
                  <th className="text-center">Creator Name</th>
                  <th className="text-center">
                    <GiHearts /> Hearts Given
                  </th>
                  <th className="text-center">Payment Pending</th>
                </tr>
              </thead>
              <tbody>
                {contentsDisplay.map((object, index) => {
                  const { hostname } = new URL(object.content);

                  return (
                    <tr key={index}>
                      <td className="text-center">
                        {object.toDate.toString().slice(0, 10)}
                      </td>
                      <td className="text-center">
                        <a
                          className="activity-content"
                          href={object.content}
                          title={object.content}
                          target="_blank"
                        >
                          {hostname}
                        </a>
                      </td>
                      <td className="text-center">{object.creatorName}</td>
                      <td className="text-center">{object.hearts}</td>
                      {/* {console.log("objecttttttttt", object)} */}
                      <td className="text-center">
                        {object.paymentDone === false
                          ? `${object.hearts}*`
                          : " "}
                      </td>
                    </tr>
                  );
                })}
                {/* {props.topContentsDisplay.dayWiseHeartsGiven.map((object, index) => {

                      const { hostname } = new URL(object.content);

                      return (
                        <tr key={index}>
                          <td></td>
                          <td>
                            <a href={object.content} title={object.content}>
                              {hostname}
                            </a>
                          </td>
                          <td className="text-center">{object.hearts}</td>
                          <td></td>
                        </tr>
                      );
                    })} */}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Activity;
