/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SignUp from "components/Authentication/signUp";
// import Dashboard from "views/Dashboard.js";
import CreatorDashboard from "views/CreatorDashboard.js";
import ClaimContent from "views/claimContent.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import Rtl from "views/Rtl.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import UserDashboard from "views/UserDashboard.js";
import RedeemHearts from "views/RedeemHearts.js";
import VerifyContent from "views/VerifyContent.js";
import Requests from "views/Requests";
// import ClaimContent from "views/ClaimContent";

var routes = [
  {
    path: "/userdashboard",
    name: "User Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-badge",
    active_img_src: "/icons/user_dashboard_active.png", //{ userDashboardActiveImg },
    img_src: "/icons/user_dashboard.png", //{ userDashboardImg },
    component: UserDashboard,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "Creator Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    active_img_src: "/icons/creator_dashboard_active.png", //{ creatorDashboardActiveImg },
    img_src: "/icons/creator_dashboard.png", //{ creatorDashboardImg },
    component: CreatorDashboard,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    active_img_src: "/icons/profile.png",
    img_src: "/icons/refresh (1).png",
    component: UserProfile,
    layout: "/admin",
  },
  // {
  //   path: "/contentverification",
  //   name: "Claim Content",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-tap-02",
  //   component: ClaimContent,
  //   layout: "/admin",
  // },
  // {
  //   path: "/redeem-hearts",
  //   name: "Redeem Hearts",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-gift-2",
  //   component: RedeemHearts,
  //   layout: "/admin",
  // },
  // {
  //   path: "/verify-content",
  //   name: "Verify Content",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-setting-2",
  //   component: VerifyContent,
  //   layout: "/admin",
  // },
  {
    path: "/request",
    name: "Claim Approval",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-setting-2",
    component: Requests,
    layout: "/admin",
    img_src: "/icons/spent.png",
    active_img_src: "/icons/tick_large.png",
  },
  // {
  //   path: "/dashboard",
  //   name: "User Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: UserDashboard,
  //   layout: "/user",
  // },

  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: "tim-icons icon-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/map",
  //   name: "Map",
  //   rtlName: "خرائط",
  //   icon: "tim-icons icon-pin",
  //   component: Map,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: "tim-icons icon-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },

  // {
  //   path: "/tables",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: "tim-icons icon-align-center",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-support",
  //   name: "RTL Support",
  //   rtlName: "ار تي ال",
  //   icon: "tim-icons icon-world",
  //   component: Rtl,
  //   layout: "/rtl",
  // },
];

export default routes;
