import React, { useState } from "react";
import cookie, { useCookies } from "react-cookie";
import { bake_cookie } from "sfcookies";
import { Link, Redirect } from "react-router-dom";
import { authenticate, signin } from "../../apiCalls/apiCalls";
import { read_cookie } from "sfcookies";
import "./authenticatePage.css";
import locations from "../../locations.js";
import axios from "axios";
import { PaymentAPI, LittleHeartsAPI } from "../../backend";
import { updateGuestAcc2 } from "apiCalls/apiCalls";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/icons/logo.png";
const SignIn = () => {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    error: "",
    loading: "",
    doRedirect: false,
  });

  // const [cookies, setCookie] = useCookies(["userToken"]);
  const user = read_cookie("userInfo");
  const cookie_key1 = "userToken";
  const cookie_key2 = "userInfo";

  const { email, password, error, loading, doRedirect } = userData;

  const handleChanges = (property) => (event) => {
    setUserData({
      ...userData,
      [property]: event.target.value,
    });
  };

  const talkToBackend = (event) => {
    event.preventDefault();

    setUserData({
      ...userData,
      error: "",
      loading: true,
    });

    let signUpAgainToken = read_cookie("userToken");
    let signUpAgainInfo = read_cookie("userInfo");

    if (signUpAgainToken.length === 0 && signUpAgainInfo.length === 0) {
      signUpAgainToken = undefined;
      signUpAgainInfo = undefined;
    }
    const guestToken = cookie.load("userTokenLH");
    const guestInfo = cookie.load("userInfoLH");

    let ourid;

    if (guestInfo) {
      ourid = guestInfo;
    }
    if (signUpAgainInfo) {
      ourid = signUpAgainInfo._id;
    }

    signin({ ourid, email, password })
      .then((data) => {
        if (data.error) {
          setUserData({
            ...userData,
            error: data.error,
            loading: false,
          });
        } else {
          authenticate(data, () => {
            let myPromise = new Promise(function (myResolve, myReject) {
              cookie.remove("userInfoLH");
              cookie.remove("userTokenLH");

              bake_cookie(cookie_key1, data.token);
              bake_cookie(cookie_key2, data.user);
              myResolve();
            });

            myPromise.then(
              function () {
                setUserData({
                  ...userData,
                  email: "",
                  password: "",
                  error: "",
                  loading: false,
                  doRedirect: true,
                });
              },
              function (error) {
                console.log("line 70 ", error);
              }
            );
          });
        }
      })
      .catch(console.log("SIGN IN REQUEST FAILED"));
  };
  let reqLocation = "";
  const performRedirect = () => {
    if (doRedirect) {
      console.log("Redirect");
      console.log(user);
      if (user.isFirstSignedIn) {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        locations.map((location) => {
          if (tz === location.timeZoneInApp) {
            reqLocation = location.timeZoneInPC;
            axios
              .post(
                `${LittleHeartsAPI}/updatezone?tz=${reqLocation}&id=${user._id}`
              )
              .then((response) => {
                console.log("response...", response);
                // if(response.message!="Success"){
                // console.log(response.data.transaction);
                // }
              })
              .catch((err) => {
                console.log("errrrrrrrrrrrrrrrrr", err);
              });

            let body = {
              id: user._id,
              user: {
                userName: user.userName,
                email: user.email,
                displayName: user.displayName,
                age: user.age,
                gender: user.gender,
                region: reqLocation,
              },
            };

            updateGuestAcc2(body)
              .then((data) => {
                if (data.error) {
                  console.log("Error", data.error);
                } else {
                  bake_cookie("userInfo", data);
                }
              })
              .catch(console.log("Creator account updated"));
          }
        });
        notify();
      }
      console.log("user user user", user);
      return <Redirect to="/admin/userdashboard" />;
    }
  };

  const errorMessage = () => {
    return (
      <div
        className="errorMsg"
        style={{
          display: error ? "" : "none",
          textAlign: "center",
          width: "100%",
          margin: "auto",
          position: "fixed",
          top: "5%",
          zIndex: 15,
          // backgroundColor: "red",
          padding: "5px",
          color: "red",
        }}
      >
        <p>{error}</p>
      </div>
      // errorMsg()
      // toast(`${error}`, {
      //   position: toast.POSITION.TOP_CENTER,
      // })
    );
  };

  const loadingMessage = () => {
    return (
      loading && (
        <div className="loadingMsg" style={{ display: loading ? "" : "none" }}>
          <p style={{ textAlign: "center" }}>Loading...</p>
        </div>
      )
    );
  };

  const signInForm = () => {
    return (
      <div id="signIn-background">
        <div id="signIn-content-container">
          <div id="signIn-content">
            <div id="signIn-content-inner-container">
              <div id="signIn-content-inner">
                <div id="signIn-content-inner-img-container">
                  <img id="signIn-content-inner-img" src={logo} alt="" />
                </div>
                <div id="signIn-content-inner-heading-container">
                  <h1 id="signIn-content-inner-heading">
                    Support your favorite content creators
                  </h1>
                </div>
                <div id="signIn-content-inner-sub-heading-container">
                  <p id="signIn-content-inner-sub-heading">
                    You can support content creators by sending a little hearts.
                    It’s a way of thanking them for making great content.
                  </p>
                </div>
                <div id="about-us-button-container">
                  New Here?
                  <a id="about-us-button" href="/home">
                    About Us
                  </a>
                </div>
              </div>
            </div>
            <div id="signIn-content-form-card-container">
              <div id="signIn-content-form-card">
                <div id="signIn-content-form-card-inner">
                  <div id="form-card-heading-container">
                    <div id="form-card-heading">Log in</div>
                    <div id="form-card-sub-heading">New here? Sign up</div>
                  </div>
                  <div id="form-card-input-container">
                    <form id="form-card">
                      <div id="form-card-inputs">
                        <div id="form-card-email-input-container">
                          <input
                            id="form-card-email-input"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={handleChanges("email")}
                            autoFocus
                          ></input>
                        </div>
                        <div id="form-card-password-input-container">
                          <input
                            id="form-card-password-input"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={handleChanges("password")}
                          ></input>
                        </div>
                      </div>
                      <div id="form-card-footer">
                        <div id="form-card-forgot-password">
                          <a
                            id="form-card-forgot-password-text"
                            href="/forgotPassword"
                          >
                            Forgot password?
                          </a>
                        </div>
                        <div id="form-card-LogIn-button-container">
                          <button
                            type="button"
                            onClick={talkToBackend}
                            id="form-card-LogIn-button"
                          >
                            Log In
                          </button>
                        </div>
                      </div>
                    </form>
                    {/* <div></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div>LittleHeartsSignIn</div> */}
      </div>

      // <div>
      //   <div className="main-content">
      //     {/* Navbar */}
      //     <nav className="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
      //       <div className="container px-4">
      //         <a
      //           className="navbar-brand"
      //           style={{ color: "white", position: "static" }}
      //           // href="https://www.creative-tim.com/product/argon-dashboard"
      //           target="_blank"
      //         >
      //           Little Hearts
      //         </a>
      //         <div
      //           className="collapse navbar-collapse"
      //           id="navbar-collapse-main"
      //         >
      //           {/* Collapse header */}
      //           <div className="navbar-collapse-header d-md-none">
      //             <div className="row">
      //               <div className="col-6 collapse-brand">
      //                 {/* <a href="../index.html">Argon</a> */}
      //               </div>
      //               <div className="col-6 collapse-close">
      //                 <button
      //                   type="button"
      //                   className="navbar-toggler"
      //                   data-toggle="collapse"
      //                   data-target="#navbar-collapse-main"
      //                   aria-controls="sidenav-main"
      //                   aria-expanded="false"
      //                   aria-label="Toggle sidenav"
      //                 >
      //                   <span />
      //                   <span />
      //                 </button>
      //               </div>
      //             </div>
      //           </div>
      //           {/* Navbar items */}
      //           <ul className="navbar-nav ml-auto">
      //             <li className="nav-item">
      //               <a
      //                 className="nav-link nav-link-icon"
      //                 href="/signIn"
      //                 // target="_blank"
      //               >
      //                 <i className="ni ni-key-25" />
      //                 {/* <Link to="/signIn"> */}
      //                 <span className="nav-link-inner--text">Sign In</span>
      //                 {/* </Link> */}
      //               </a>
      //             </li>
      //           </ul>
      //         </div>
      //       </div>
      //     </nav>
      //     {/* Header */}
      //     <div className="header bg-gradient-primary py-7 py-lg-6">
      //       <div className="container">
      //         <div className="header-body text-center mb-7">
      //           <div className="row justify-content-center">
      //             <div className="col-lg-5 col-md-6">
      //               <h1 className="text-white">Welcome to Little Hearts!</h1>
      //               <p className="text-lead text-white">Sign In</p>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //       <div className="separator separator-bottom separator-skew zindex-100">
      //         <svg
      //           x={0}
      //           y={0}
      //           viewBox="0 0 2560 100"
      //           preserveAspectRatio="none"
      //           version="1.1"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <polygon
      //             className="fill-default"
      //             points="2560 0 2560 100 0 100"
      //           />
      //         </svg>
      //       </div>
      //     </div>
      //     {/* Page content */}
      //     <div className="container mt--8 pb-5">
      //       <div className="row justify-content-center">
      //         <div className="col-lg-5 col-md-7">
      //           <div className="card bg-secondary shadow border-0">
      //             <div className="card-body px-lg-5 py-lg-5">
      //               <div className="text-center text-muted mb-4">
      //                 <small>Sign in with credentials</small>
      //               </div>
      //               <form role="form">
      //                 <div className="form-group mb-3">
      //                 </div>
      //                 <div className="form-group mb-3">
      //                   <div className="input-group input-group-alternative">
      //                     <input
      //                       className="form-control"
      //                       placeholder="Email"
      //                       type="email"
      //                       value={email}
      //                       onChange={handleChanges("email")}
      //                       style={{
      //                         color: "black",
      //                         border: "1px solid black",
      //                       }}
      //                       autoFocus
      //                     />
      //                   </div>
      //                 </div>
      //                 <div className="form-group">
      //                   <div className="input-group input-group-alternative">
      //                     <input
      //                       className="form-control"
      //                       placeholder="Password"
      //                       type="password"
      //                       value={password}
      //                       onChange={handleChanges("password")}
      //                       style={{
      //                         color: "black",
      //                         border: "1px solid black",
      //                       }}
      //                     />
      //                   </div>
      //                 </div>
      //                 <div className="custom-control custom-control-alternative custom-checkbox">
      //                   <input
      //                     className="custom-control-input"
      //                     id=" customCheckLogin"
      //                     type="checkbox"
      //                   />
      //                 </div>
      //                 <div className="text-center">
      //                   <button
      //                     type="button"
      //                     className="btn btn-primary my-1"
      //                     onClick={talkToBackend}
      //                   >
      //                     Sign In
      //                   </button>
      //                 </div>
      //               </form>
      //             </div>
      //           </div>
      //           <div className="row mt-3">
      //             <div className="col-6">
      //               <a href="/forgotPassword" className="text-light">
      //                 <small>Forgot password?</small>
      //               </a>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   {/* Footer */}
      //   <footer className="py-4">
      //     <div className="container">
      //       <div className="row align-items-center justify-content-xl-between">
      //         <div className="col-xl-6">
      //           <div className="copyright text-center text-xl-left text-muted">
      //             © 2021{" "}
      //             <a
      //               // href="https://www.creative-tim.com/product/argon-dashboard"
      //               className="font-weight-bold ml-1"
      //               target="_blank"
      //             >
      //               Little Hearts
      //             </a>
      //           </div>
      //         </div>
      //         <div className="col-xl-6">
      //           <ul className="nav nav-footer justify-content-center justify-content-xl-end">
      //             <li className="nav-item">
      //               <a
      //                 //   href="https://www.creative-tim.com"
      //                 className="nav-link"
      //                 target="_blank"
      //               >
      //                 Little Hearts
      //               </a>
      //             </li>
      //             <li className="nav-item">
      //               <a
      //                 //   href="https://www.creative-tim.com/presentation"
      //                 className="nav-link"
      //                 target="_blank"
      //               >
      //                 About Us
      //               </a>
      //             </li>
      //           </ul>
      //         </div>
      //       </div>
      //     </div>
      //   </footer>
      // </div>
    );
  };
  const notify = () => {
    toast(`Your current location is ${reqLocation}`, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const errorMsg = () => {
    toast(`${error}`, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  toast.configure();
  return (
    <div>
      {errorMessage()}
      {loadingMessage()}
      {signInForm()}
      {performRedirect()}
      {/* <p>{JSON.stringify(userData)}</p> */}
    </div>
  );
};

export default SignIn;
