import React, { useEffect, useState } from "react";
import { read_cookie, bake_cookie } from "sfcookies";
import axios from "axios";
import { PaymentAPI, LittleHeartsAPI } from "../backend";
import { GiHearts, GiMoneyStack } from "react-icons/gi";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import { getContentCreatorData, getCreatorReport } from "../apiCalls/apiCalls";

const CreatorSummary = () => {
  const [totalHeartsAvailable, setTotalHeartsAvailable] = useState(0);
  const [totalHeartsObtained, setTotalHeartsObtained] = useState(0);
  const [totalHeartsRedeemed, setTotalHeartsRedeemed] = useState(0);
  // Unit Price is the priuce of one heart which we will update after calling the API
  const [unitPrice, setUnitPrice] = useState(1);
  const [creatorReportData, setCreatorReportData] = useState([]);

  //This is used to display to the screen all the data related to the creator
  // The array is an object array which necessarily contains the date and may contain HeartsGiven, HeartsOnHand or HeartsRedeemed
  const [creatorLogsData, setCreatorLogsData] = useState([]);
  //This is a temporary state which stores the data related to the creator
  const [creatorData, setCreatorData] = useState([]);
  const reportData = [];
  useEffect(() => {
    const getAllCreatorLogs = async () => {
      const token = await read_cookie("userToken");
      console.log("Token ::", token);

      // We will get the array from the API call
      getContentCreatorData(token).then((data) => {
        if (data.error) {
          console.log("Error is", data.error);
        } else {
          //alldata is the array we got from API
          const alldata = data.arr;
          const logData = [];
          //Traversing through whole array
          for (let i = 0; i < alldata.length; i++) {
            if (alldata[i].heartsObtained) {
              // console.log("hearts obtaineddddddddd", alldata[i].heartsObtained);
              const heartObtained = alldata[i].heartsObtained;
              for (let j = 0; j < heartObtained.length; j++) {
                const heartData = {
                  date: heartObtained[j].currentTime,
                  heartsReceived: heartObtained[j].numberOfHeartsGiven,
                  heartsOnhand: heartObtained[j].numberOfHeartsOnHand,
                };
                logData.push(heartData);
              }
            }
            if (alldata[i].redeemedArray) {
              const heartRedeemed = alldata[i].redeemedArray;
              for (let j = 0; j < heartRedeemed.length; j++) {
                const heartData = {
                  date: heartRedeemed[j].currentTime,
                  heartsRedeemed: heartRedeemed[j].heartsCountRedeemed,
                };
                logData.push(heartData);
              }
            }
            console.log("loggggg dataaaaaaaa", logData);
          }
          setCreatorData(logData);
        }
      });
    };
    //getting the unit price of a heart from the database and updating state
    axios.get(`${LittleHeartsAPI}/storage1/unitPrice`).then((response) => {
      console.log(response);
      if (response.data.content != null) {
        setUnitPrice(response.data.content.codeValue);
      }
    });
    getAllCreatorLogs();
    const token = read_cookie("userToken");
    getCreatorReport(token).then((data) => {
      if (data.error) {
        console.log("Error is", data.error);
      } else {
        console.log("contenttttttttttttttttttttttttttt", data.content);
        for (let i = 0; i < data.content.length; i++) {
          const reportHeartsData = {
            URL: data.content[i].contentURL,
            heartsReceived: data.content[i].totalHeartsObtained,
            heartsRedeemed: data.content[i].totalHeartsRedeem,
            heartsOnHand: data.content[i].totalHeartsAvailable,
          };
          reportData.push(reportHeartsData);
        }

        setCreatorReportData(reportData);
      }
    });
  }, []);

  // This useEffect will be rendered when the whole temporary array is filled and ready to get finalised
  useEffect(() => {
    if (creatorLogsData) {
      // sorting whole array on the basis of date in descending order
      const temp = creatorData;
      temp.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      setCreatorLogsData(temp);
    }
  }, [creatorData]);

  // const { user, token } = isSignedIn();
  const user = read_cookie("userInfo");
  // calling the api to get all the data related to the creator
  useEffect(async () => {
    // The api directly returns us all the three required summary, we just updated our states
    const token = await read_cookie("userToken");
    getContentCreatorData(token).then((data) => {
      if (data.error) {
        console.log("Error is", data.error);
      } else {
        setTotalHeartsObtained(data.totalHeartsObtained);
        setTotalHeartsAvailable(data.totalHeartsAvailable);
        setTotalHeartsRedeemed(data.totalHeartsRedeem);
      }
    });
  }, []);
  return (
    <div>
      <div class="mt-3">
        <div class="top-cards-row">
          <div class="top-cards-column">
            <img src={window.location.origin + "/icons/received.png"} alt="" />
            <div className="count-column">
              <div className="heart-count">{totalHeartsObtained}</div>
              <div>Total Hearts Received</div>
            </div>
          </div>
          <div class="top-cards-column">
            <img src={window.location.origin + "/icons/redeemed.png"} alt="" />
            <div className="count-column">
              <div className="heart-count">{totalHeartsRedeemed}</div>
              <div>Received Paymet</div>
            </div>
          </div>
          <div class="top-cards-column">
            <img src={window.location.origin + "/icons/on hand.png"} alt="" />
            <div className="count-column">
              <div className="heart-count">{totalHeartsAvailable}</div>
              <div>Awaiting Payment</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <Card className="card-custom">
          <CardHeader className="card-header">
            <CardTitle tag="h4" className="report-card-title card-title">
              Creator Report
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Table id="report-header">
              {/* className="tablesorter" responsive */}
              <thead className="text-primary">
                <tr id="report-top-row">
                  {/* <th className="text-center">Date</th> */}
                  <th className="text-center">
                    <GiHearts /> URL
                  </th>
                  <th className="text-center">
                    <GiHearts /> Received
                  </th>
                  <th className="text-center">
                    <GiHearts /> Redeemed
                  </th>
                  <th className="text-center">
                    <GiHearts /> On hand
                  </th>
                  {/* <th className="text-center">
                    <GiMoneyStack /> Price
                  </th>
                  <th className="text-center">
                    <GiMoneyStack /> Amount
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {console.log("creatorrrrrrrr dataaaaaaaaaaaaaa", creatorData)}
                {creatorReportData &&
                  creatorReportData.map((object, idx) => {
                    return (
                      <tr key={idx}>
                        {/* <td className="text-center">
                          {object.date.toString().slice(0, 10)}
                        </td> */}
                        <td className="text-center">
                          {object.URL ? object.URL : null}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {object.heartsReceived ? object.heartsReceived : null}
                        </td>
                        <td className="text-center">
                          {object.heartsRedeemed ? object.heartsRedeemed : null}
                        </td>
                        <td className="text-center">
                          {object.heartsOnHand ? object.heartsOnHand : null}
                        </td>
                        {/* <td className="text-center">{unitPrice}</td>
                        <td className="text-center">
                          {object.heartsOnhand
                            ? object.heartsOnhand * unitPrice
                            : null}
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
      {/* <div class="mt-3">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              <center>Summary</center>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div
              className="p-3 my-2 rounded"
              style={{ background: "#2972f6", color: "white" }}
            >
              <Toast className="text-center">
                <ToastHeader>Total Hearts Received</ToastHeader>
                <ToastBody>
                  <GiHearts /> {totalHeartsObtained}
                </ToastBody>
              </Toast>
            </div>
            <div
              className="p-3 my-2 rounded"
              style={{ background: "#2972f6", color: "white" }}
            >
              <Toast className="text-center">
                <ToastHeader>Total Hearts Redeemed</ToastHeader>
                <ToastBody>
                  <GiHearts /> {totalHeartsRedeemed}
                </ToastBody>
              </Toast>
            </div>
            <div
              className="p-3 my-2 rounded"
              style={{ background: "#2972f6", color: "white" }}
            >
              <Toast className="text-center">
                <ToastHeader>Hearts On hand</ToastHeader>
                <ToastBody>
                  <GiHearts /> {totalHeartsAvailable}
                </ToastBody>
              </Toast>
            </div>
          </CardBody>
        </Card>
      </div> */}
    </div>
  );
};

export default CreatorSummary;
