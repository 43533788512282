/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { getUser, claimRequestFromAdmin } from "../apiCalls/apiCalls";

import { read_cookie } from "sfcookies";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from "variables/charts.js";
import axios from "axios";
import { PaymentAPI, LittleHeartsAPI } from "../backend";
import "../assets/manualCSS/dashboards.css";
import { updateMessageToConsumers } from "../apiCalls/apiCalls.js";
import { getContentCreatorData, getCreatorReport } from "../apiCalls/apiCalls";

function RequestClaim(props) {
  const token = read_cookie("userToken");
  const [url, setUrl] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const user = read_cookie("userInfo");
  // const [details, setdetails] = useState([]);
  // const [refreshState, setRefreshState] = useState(true);
  const [requestFlashCardDisplay, setRequestFlashCardDisplay] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [details, setdetails] = useState([]);
  const [search, setsearch] = useState("All");
  const [refreshState, setRefreshState] = useState(true);
  const [msg, setmsg] = useState("");
  // const token = read_cookie("userToken");
  const toggle = () => setShouldDisplayModal(!shouldDisplayModal);
  const [shouldDisplayModal, setShouldDisplayModal] = React.useState(false);
  const [data, setdata] = useState({ message: " ", id: "" });
  const handleChange = (e) => {
    setsearch(e.target.value);
  };
  const handleChange1 = (e) => {
    setmsg(e.target.value);
  };
  const handlesub = () => {
    let temp = [];
    // details = details.filter((object) => {
    //   return object.status === search || search === "All";
    // });
  };
  function handleUpdate(para) {
    let requestBody = {
      contentPlatformID: data.id,
      message: msg,
    };
    console.log(requestBody);
    updateMessageToConsumers(token, requestBody).then((data) => {
      if (data.error) {
        console.log("Error", data.error);

        alert("Message not Published! Try again after some time.");
      } else {
        console.log(data);

        alert("Message Published!");
      }
    });

    setShouldDisplayModal(false);
  }
  // Calling the API from routes/transaction.js where id of the user will be send through params
  // In response we will be getting the full object containing the key data in which one transaction key is present, our whole transaction details will be stored.
  // And accordingly we will get the transaction array from the response.
  useEffect(async () => {
    let userInfo = await read_cookie("userInfo");

    axios
      .get(`${LittleHeartsAPI}/getuserclaimrequests?email=${userInfo.email}`)
      .then((response) => {
        if (response.message != "Results received!") {
          console.log("contenttttttttttttttttttttttt", response.data.arr);
          setdetails(response.data.arr.reverse());
        }
      });
    const token = read_cookie("userToken");
    getCreatorReport(token).then((data) => {
      if (data.error) {
        console.log("Error is", data.error);
      } else {
        // console.log(data.content);
      }
    });
  }, [refreshState]);
  const handleview = (idx) => {
    // let updatedData=details
    console.log("detailsssssssssssssssss", details[idx]);
    setdata({
      ...data,
      message: details[idx].message,
      id: details[idx].url,
    });
    setmsg(details[idx].message);
    setShouldDisplayModal(true);
  };

  document.addEventListener("click", (event) => {
    console.log(
      "clickeedddddddddddddddddddddddd",
      event.target.classList,
      event.target.id
    );
    if (
      !event.target.id ||
      (event.target.id !== "request-flash-card" &&
        event.target.id !== "request-flash-card-content" &&
        event.target.id !== "request-flash-card-tick-icon-img-container" &&
        event.target.id !== "request-flash-card-tick-icon-img" &&
        event.target.id !== "request-flash-card-heading-msg" &&
        event.target.id !== "request-flash-card-close-button")
    ) {
      setRequestFlashCardDisplay(false);
    }
  });

  function handleSubmit() {
    console.log(user);
    if (!url && !description) {
      setErrorMessage("Please Enter url and description URL!");
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }
    if (!url) {
      setErrorMessage("Please Enter URL");
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }
    if (!description) {
      setErrorMessage("Please Enter Description");
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }
    setErrorMessage("");

    claimRequestFromAdmin(user.email, url, description).then((data) => {
      if (data.error) {
        console.log("error", data.error);
        setErrorMessage("Somethings wrong with backend!");
        setTimeout(() => setErrorMessage(""), 3000);
      } else {
        setRequestFlashCardDisplay(true);
        if (data.msg) {
          console.log("msggggggggg", data.msg);
          setRequestMessage(data.msg);
          setErrorMessage(data.msg);
          // setUrl("");
          // setDescription("");
          setTimeout(() => setErrorMessage(""), 3000);
        } else if (data.message) {
          console.log("messagggggggggeeeee", data.message);
          setRequestMessage(data.message);
          setErrorMessage(data.message);
          // setUrl("");
          // setDescription("");
          setTimeout(() => setErrorMessage(""), 3000);
        }
      }
    });
  }

  return (
    <>
      {/* request flash card */}
      <div
        style={{ display: requestFlashCardDisplay ? "flex" : "none" }}
        id="request-flash-card-background"
      >
        <div id="request-flash-card">
          <div id="request-flash-card-content">
            <div id="request-flash-card-tick-icon-img-container">
              <img
                id="request-flash-card-tick-icon-img"
                src={window.location.origin + "/icons/tick_large.png"}
                alt=""
              />
            </div>
            <div id="request-flash-card-heading-msg">{requestMessage}</div>
            {/* <div id="error-flash-card-subheading-msg">Please try again</div> */}
            <div
              id="request-flash-card-close-button"
              onClick={() => {
                setRequestFlashCardDisplay(false);
                setUrl("");
                setDescription("");
              }}
            >
              Got it
            </div>
          </div>
        </div>
      </div>
      <div className="content link-content">
        <Row>
          <Col lg="10" md="12">
            <div class="mt-3">
              <Card className="card-custom">
                <CardHeader>
                  <CardTitle tag="h4" className="card-title-heading">
                    Hey! Enter the URL and Description...
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Label for="totalcost" sm={5}>
                      <center className="link-content-fields">URL*</center>
                    </Label>

                    <Col sm={6}>
                      <Input
                        id="totalcost"
                        name="totalcost"
                        placeholder="Please enter complete URL (e.g - https://www.xyz.com/)"
                        type="email"
                        onChange={(e) => setUrl(e.target.value)}
                        className="link-content-input"
                        value={url}
                      />
                      <div
                        style={{
                          marginTop: "3px",
                          marginLeft: "10px",
                          fontSize: "12px",
                          color: "rgb(240, 222, 222)",
                        }}
                      >
                        {errorMessage === "Please Enter URL"
                          ? errorMessage
                          : ""}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Label for="totalcost" sm={5}>
                      <center className="link-content-fields">
                        Description*
                      </center>
                    </Label>
                    <Col sm={6}>
                      <Input
                        id="totalcost"
                        name="totalcost"
                        placeholder="Description"
                        type="text"
                        onChange={(e) => setDescription(e.target.value)}
                        className="link-content-input"
                        value={description}
                      />
                      <div
                        style={{
                          marginTop: "3px",
                          marginLeft: "6px",
                          color: "rgb(240, 222, 222)",
                        }}
                      >
                        {errorMessage === "Please Enter Description"
                          ? errorMessage
                          : ""}
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="justify-content-center"
                    style={{ marginTop: "10px" }}
                  >
                    <Col sm={4} md="auto">
                      <div id="request-submit-button" onClick={handleSubmit}>
                        Submit
                      </div>
                      {/* <Button
                        size="sm"
                        className="btn-redeem-hearts"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button> */}
                    </Col>
                  </Row>
                  <Row
                    className="justify-content-center"
                    style={{ marginTop: "10px", color: "rgb(240, 222, 222)" }}
                  >
                    {errorMessage !== "Please Enter URL" &&
                    errorMessage !== "Please Enter Description"
                      ? errorMessage
                      : ""}
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
      <div class="mt-3">
        <Modal isOpen={shouldDisplayModal} toggle={toggle} backdrop={true}>
          <ModalHeader>Details</ModalHeader>
          <ModalBody>
            {<label for="textarea">Message:</label>}
            {
              <Input
                placeholder="something"
                type="text"
                defaultValue={data.message}
                onChange={handleChange1}
                style={{ color: "black", margin: " 0px 10px 10px 0px" }}
              />
            }

            {
              <Button
                style={{ float: "left" }}
                className="btn-fill"
                color="primary"
                onClick={() => handleUpdate()}
              >
                Update
              </Button>
            }
          </ModalBody>
        </Modal>
        <Card className="card-custom">
          <CardHeader className="d-flex flex-row justify-content-between">
            {/* <Input
              value={search}
              onChange={handleChange}
              id="hearts"
              type="select"
              name="hearts"
              style={{ color: "white", width: "150px" }}
            >
              <option value="All">All</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
              <option value="Pending">Pending</option>
            </Input>
            <button hidden="true" onClick={handlesub()}></button> */}
            <CardTitle
              tag="h4"
              style={{ width: "100%" }}
              className="card-title-heading"
            >
              History
            </CardTitle>
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => setRefreshState((refreshState) => !refreshState)}
            >
              <div className="refresh-container">
                <div className="refresh-icon-img-container">
                  <img
                    className="refresh-icon-img"
                    src={window.location.origin + "/icons/refresh (1).png"}
                    alt=""
                  />
                </div>
                <div className="refresh-msg">Refresh</div>
              </div>
              {/* <FiRefreshCcw /> */}
            </h4>
          </CardHeader>
          <CardBody>
            <Table id="history-header">
              {/* className="tablesorter" responsive */}
              <thead className="text-primary">
                <tr id="history-top-row">
                  <th className="">Url</th>
                  <th className="text-center">Description</th>
                  <th className="text-center">Request Date</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Remarks</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {[...details].map((object, idx) => {
                  return (
                    <tr key={idx}>
                      {/*According to code value ="Purchase" or "Spent" columns' data will be shown in the table  */}
                      <td id="history-content">{object.url}</td>
                      {/* <td className="" style={{ textAlign: "center" }}>
                        {object.url}
                      </td> */}
                      <td className="text-center">{object.description}</td>
                      <td className="text-center">
                        {object.claim_date.slice(0, 10)}
                      </td>
                      {console.log("statusssssssssssss", object.status)}
                      <td
                        className="text-center"
                        id={
                          object.status === "Approved"
                            ? "request-approved-msg"
                            : object.status === "Pending"
                            ? "request-pending-msg"
                            : object.status === "Rejected"
                            ? "request-rejected-msg"
                            : "request-msg"
                        }
                      >
                        {object.status}
                      </td>
                      <td className="text-center">
                        {object.remarks ? object.remarks : null}
                      </td>
                      <td>
                        {object.status === "Approved" && (
                          <Button
                            id="update-msg-button"
                            onClick={() => handleview(idx)}
                          >
                            Update
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default RequestClaim;
