/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { createGuestAccount, signin, signout } from "../../apiCalls/apiCalls";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { bake_cookie, delete_cookie } from "sfcookies";
import { read_cookie } from "sfcookies";
import axios from "axios";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  ModalBody,
  NavbarToggler,
  ModalHeader,
} from "reactstrap";

function AdminNavbar(props) {
  let history = useHistory();

  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [shouldDisplayModal, setShouldDisplayModal] = React.useState(false);
  const toggle = () => setShouldDisplayModal(!shouldDisplayModal);
  const [color, setcolor] = React.useState("navbar-transparent");

  const user = read_cookie("userInfo");
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };
  return (
    <>
      <Modal
        id="logout-modal"
        isOpen={shouldDisplayModal}
        toggle={toggle}
        backdrop={true}
        style={{
          width: "fit-content",
          minWidth: "20%",
          height: "fit-content",
          minHeight: "40%",
          // borderRadius: "15px",
        }}
      >
        <ModalHeader
          style={{
            margin: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // width: "100%",
            fontSize: "20px",
          }}
        >
          <div
            style={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              marginTop: "20px",
              width: "100%",
              fontSize: "20px",
            }}
          >
            Confirm
          </div>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div>Do you want to logout?</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                width: "80%",
                justifyContent: "space-around",
                // flexDirection: "row",
              }}
            >
              <div>
                <center>
                  <Button
                    style={{ borderRadius: "1.2rem", background: "#24d0a1" }}
                    className="btn-fill"
                    color="primary"
                    onClick={() => {
                      signout(() => {
                        createGuestAccount().then((data) => {
                          if (data.error) {
                            console.log("Error", data.error);
                          } else {
                            let signInCredentials = {
                              email: data.email,
                              password: "123456",
                            };

                            signin(signInCredentials).then((data) => {
                              if (data.error) {
                                console.log("Error", data.error);
                              } else {
                                bake_cookie("userToken", data.token);
                                bake_cookie("userInfo", data.user);

                                // delete_cookie("userToken");

                                history.push("/");
                              }
                            });
                          }
                        });
                      });
                    }}
                  >
                    Yes
                  </Button>
                </center>
              </div>
              <div>
                <center>
                  <Button
                    style={{
                      borderRadius: "1.2rem",
                      width: "106px",
                      background: "#D52941",
                    }}
                    onClick={() => {
                      setShouldDisplayModal(false);
                    }}
                  >
                    No
                  </Button>
                </center>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Navbar className={classNames("navbar-absolute", color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={toggleCollapse}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {/* <InputGroup className="search-bar">
                <Button color="link" onClick={toggleModalSearch}>
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Search</span>
                </Button>
              </InputGroup> */}
              {/* <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                >
                  <div className="notification d-none d-lg-block d-xl-block" />
                  <i className="tim-icons icon-sound-wave" />
                  <p className="d-lg-none">Notifications</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Mike John responded to your email
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      You have 5 more tasks
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Your friend Michael is in town
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Another notification
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Another one
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo user-credentials">
                    <div className="user-credentials-email">{user.email}</div>
                    <div className="user-credentials-img">
                      <img
                        alt="..."
                        src={require("assets/icons/profile.png").default}
                      />
                    </div>
                  </div>
                  {/* <b className="caret d-none d-lg-block d-xl-block" /> */}
                  <span className="d-lg-none">Log out</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  {/* <NavLink tag="li">
                    <DropdownItem className="nav-item">Profile</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Settings</DropdownItem>
                  </NavLink> */}
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem
                      className="nav-item"
                      onClick={() => setShouldDisplayModal(true)}
                    >
                      Log out
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader>
          <Input placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>
      </Modal>
    </>
  );
}

export default AdminNavbar;
