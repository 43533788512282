import React, { useState } from "react";
import logo from "../../assets/icons/logo.png";
import aboutUsImage from "../../assets/icons/FAQ.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import MyAccordion from "./MyAccordion";
const faqs = [
  {
    id: 1,
    ques: "What is Little Hearts?",
    ans: "A browser extension which can be used to show appreciation to any URL on the net, by sending little hearts. The product can grow into a micro-payment + content recommendation platform.",
  },
  {
    id: 2,
    ques: "How to use Little Hearts? ",
    ans: "Get te Little Hearts extension from the chrome extension store, give hearts to the content/creators you like, then sign up on our dashboard and make payment for the hearts given",
  },
  {
    id: 3,
    ques: "How many Type of users can be there?",
    ans: "There are two types of users:- 1.The content consumer - blog reader, viewer of youtube, etc. 2.The page owner - the person who has created the content on the webpage",
  },
  {
    id: 4,
    ques: "How will it work for the first user type(content consumer)?",
    ans: "Main use case for the content consumer / blog reader is sending hearts to pages. Steps:- User browsing website -> see content they like -> shortcut key/button to send as many little hearts to the page/URL",
  },
  {
    id: 5,
    ques: "How will it work for the second user type(page owner)?",
    ans: "Create a Little hearts ‘owners’ account and link all the page urls they own -> provide some proof for page links and after verification from the admin, the page can be claimed -> Get an official account tag -> Receive the accumulated hearts against your own account",
  },
];

const FAQ = () => {
  const [data, setData] = useState(faqs);
  return (
    <div>
      <div id="FAQ-section">
        <div id="FAQ-content-section">
          <div id="FAQ-content-container">
            <div id="FAQ-heading">FAQ's</div>
            <div id="FAQ-question-container">
              {data.map((curElem) => {
                const { id } = curElem;
                return <MyAccordion key={id} {...curElem} />;
              })}
            </div>
          </div>
        </div>
        <div id="FAQ-image-section">
          <div id="FAQ-image-container">
            <img src={aboutUsImage} id="FAQ-image" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
