import React, { useEffect, useState } from "react";
import { read_cookie, bake_cookie } from "sfcookies";
import { FiRefreshCcw } from "react-icons/fi";
import { GiHearts, GiMoneyStack } from "react-icons/gi";

// reactstrap components

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import axios from "axios";
import { PaymentAPI, LittleHeartsAPI } from "../backend";
const Log = () => {
  const [currentTransactions, setCurrentTransactions] = useState([]);
  const [refreshState, setRefreshState] = useState(true);
  // Calling the API from routes/transaction.js where id of the user will be send through params
  // In response we will be getting the full object containing the key data in which one transaction key is present, our whole transaction details will be stored.
  // And accordingly we will get the transaction array from the response.
  useEffect(async () => {
    let userInfo = await read_cookie("userInfo");
    console.log(userInfo._id);
    axios
      .get(`${LittleHeartsAPI}/transaction/${userInfo._id}`)
      .then((response) => {
        // console.log(response);
        if (response.data.transaction != null) {
          console.log(response.data.transaction);

          setCurrentTransactions(response.data.transaction);
        }
      });
  }, [refreshState]);

  return (
    <div class="mt-3">
      <Card className="card-custom">
        <CardHeader className="d-flex flex-row justify-content-between">
          <CardTitle
            tag="h4"
            style={{ width: "100%" }}
            className="card-title-heading"
          >
            Transaction Log
          </CardTitle>
          <h4
            style={{ cursor: "pointer" }}
            onClick={() => setRefreshState((refreshState) => !refreshState)}
          >
            <div className="refresh-container">
              <div className="refresh-icon-img-container">
                <img
                  className="refresh-icon-img"
                  src={window.location.origin + "/icons/refresh (1).png"}
                  alt=""
                />
              </div>
              <div className="refresh-msg">Refresh</div>
            </div>
            {/* <FiRefreshCcw /> */}
          </h4>
        </CardHeader>
        <CardBody>
          <Table>
            {/* className="tablesorter" responsive */}
            <thead className="text-primary" id="log-header">
              <tr id="log-top-row">
                <th className="text-center">Date</th>
                <th className="text-center">
                  <GiHearts /> Paid
                </th>
                <th className="text-center">
                  <GiHearts /> Rewards
                </th>
                <th className="text-center">
                  <GiHearts /> Spent
                </th>
                <th className="text-center">
                  <GiHearts /> Available
                </th>
                <th className="text-center">
                  <GiMoneyStack /> Price
                </th>
                <th className="text-center">
                  <GiMoneyStack /> Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {[...currentTransactions].reverse().map((object, idx) => {
                return (
                  <tr>
                    <td className="text-center">
                      {object.Date.toString().slice(0, 10)}
                    </td>
                    {/*According to code value ="Purchase" or "Spent" columns' data will be shown in the table  */}
                    <td style={{ textAlign: "center" }}>
                      {object.code == "Purchase" ? object.heartCount : null}
                    </td>
                    <td className="text-center">
                      {object.code == "Purchase" ? object.reward : null}
                    </td>
                    <td className="text-center">
                      {object.code == "Spent" ? object.heartCount : null}
                    </td>
                    <td className="text-center">
                      {object.countAfter != -1 ? object.countAfter : null}
                    </td>
                    <td className="text-center">{object.unitPrice}</td>
                    <td className="text-center">
                      {object.code == "Spent" ? -object.amount : object.amount}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default Log;
