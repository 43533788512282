import React, { useState, useEffect } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import {
  authenticate,
  signup,
  secondSignUpGuestUpdate,
} from "../../apiCalls/apiCalls";
import { read_cookie, bake_cookie } from "sfcookies";
import cookie from "react-cookie";
import "./authenticatePage.css";
import logo from "../../assets/icons/logo.png";
const SignUp = () => {
  //To check if user landed on Signup page only thorough extension
  //We will get all the queries in the url visited on Sign Up page
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  //Currently we have only two queries, namely referrer which is set to littleheartsextension and authorization which is set to true
  const referrer = searchParams.get("referrer");
  const authorization = searchParams.get("authorization");
  console.log(referrer, authorization);
  //When user visit the signUp Page we will check the following
  useEffect(() => {
    // if the queries are matched we will store thr queries in the local storage for now and redirect the user to actual signUp page
    if (referrer === "littleheartsextension" && authorization === "true") {
      // Setting the local Storage
      localStorage.setItem("Referrer", referrer);
      localStorage.setItem("Authorization", authorization);
      // Redirecting to the signUp page without queries
      window.location.replace("/signUp");
    }
    // If queries are not there, means the user have been landed to SignUp page
    else {
      //We will make sure that the local storage has the required credentials discussed above
      //If user have them we will allow user a one time visit and then delete the local storage content
      if (
        localStorage.getItem("Referrer") === "littleheartsextension" &&
        localStorage.getItem("Authorization") === "true"
      ) {
        localStorage.removeItem("Referrer");
        localStorage.removeItem("Authorization");
      }
      //If there is no content in local storage then we will redirect user to signIn page
      else {
        alert("SignUp Page can only be visited through the Extension!");
        window.location.replace("/signIn");
      }
    }
  }, []);

  const [userData, setUserData] = useState({
    userName: "",
    email: "",
    password: "",
    creatorAccount: false,
    error: "",
    success: false,
    doRedirect: false,
  });
  const [isSignedUp, setSignUp] = useState(true);
  const [guestToManual, setGuestToManual] = useState(false);
  const [multipleSignUp, setMultipleSignUp] = useState(false);

  const cookie_key1 = "userToken";
  const cookie_key2 = "userInfo";

  const {
    userName,
    email,
    password,
    creatorAccount,
    error,
    success,
    doRedirect,
  } = userData;

  const handleChanges = (property) => (event) => {
    setUserData({
      ...userData,
      error: false,
      [property]: event.target.value,
    });
  };

  const talkToBackend = (event) => {
    event.preventDefault();
    setUserData({ ...userData, error: false });

    let signUpAgainToken = read_cookie("userToken");
    let signUpAgainInfo = read_cookie("userInfo");

    if (signUpAgainToken.length === 0 && signUpAgainInfo.length === 0) {
      signUpAgainToken = undefined;
      signUpAgainInfo = undefined;
    }

    const guestToken = cookie.load("userTokenLH");
    const guestInfo = cookie.load("userInfoLH");

    console.log("log1", guestToken);
    console.log("log2", guestInfo);
    console.log("log3", signUpAgainInfo);
    console.log("log4", signUpAgainToken);

    if (
      (guestToken && guestInfo) ||
      (signUpAgainInfo && signUpAgainToken)
      // (signUpAgainInfo !== undefined && signUpAgainToken !== undefined) ||
      // (signUpAgainInfo.length && signUpAgainToken.length)
    ) {
      let body = {};
      let id;
      let tokenValue;

      if (guestToken && guestInfo) {
        console.log("Guest Account Updation");

        // id = guestInfo;

        body = {
          id: guestInfo,
          user: { userName: userName, email: email, password: password },
        };

        tokenValue = guestToken;
        // setSignUp(false);
      } else if (signUpAgainToken && signUpAgainInfo) {
        console.log("Second time sign up");

        // id = signUpAgainInfo._id;

        body = {
          id: signUpAgainInfo._id,
          user: { userName: userName, email: email, password: password },
        };

        tokenValue = signUpAgainToken;
      }

      console.log("this is the body", body);
      secondSignUpGuestUpdate(body)
        // secondSignUpGuestUpdate({ id, userName, email, password })
        .then((data) => {
          if (data.error) {
            setUserData({ ...userData, error: data.error, success: false });
          } else {
            authenticate(data, () => {
              // cookie.remove("userTokenLH");
              // cookie.remove("userInfoLH");

              console.log("TokenValue", data.token);

              // bake_cookie(cookie_key1, tokenValue);
              // bake_cookie(cookie_key2, data);

              // bake_cookie(cookie_key1, data.token);
              // bake_cookie(cookie_key2, data.user);

              setUserData({
                ...userData,
                userName: "",
                email: "",
                password: "",
                error: "",
                success: true,
                doRedirect: true,
              });
            });
            setSignUp(false);
          }
        })
        .catch(console.log("Error in updation of guest account"));
    } else if (
      !guestInfo &&
      !guestToken &&
      !signUpAgainInfo &&
      !signUpAgainToken
    ) {
      console.log({ userName, email, password });
      signup({ userName, email, password })
        .then((data) => {
          if (data.error) {
            setUserData({ ...userData, error: data.error, success: false });
          } else {
            authenticate(data, () => {
              // bake_cookie(cookie_key1, data.token);
              // bake_cookie(cookie_key2, data.user);
              setSignUp(false);
              setUserData({
                ...userData,
                userName: "",
                email: "",
                password: "",
                error: "",
                success: true,
                doRedirect: true,
              });
            });
          }
        })
        .catch(console.log("ERROR IN SIGNUP"));
    }
  };

  const successMessage = () => {
    return (
      <div className="successMsg" style={{ display: success ? "" : "none" }}>
        <p>
          You are successfully signed up in Little Hearts
          <p>
            Login <Link to="/signin">here</Link>
          </p>
        </p>
      </div>
    );
  };

  const errorMessage = () => {
    return (
      // Previously we were just displaying a message to user
      <div
        className="errorMsg"
        style={{
          display: error ? "" : "none",
          textAlign: "center",
          width: "100%",
          margin: "auto",
          position: "fixed",
          top: "5%",
          zIndex: 15,
          // backgroundColor: "red",
          padding: "5px",
          color: "red",
        }}
      >
        {
          // If the error message we recieved is this, then we can redirect to signin page
          error === "This email is already registered" ? (
            (alert(`${error}. Redirecting to sign in page...`),
            window.location.replace("/signIn"))
          ) : (
            <p style={{ color: "white" }}>{error}</p>
          )
        }
      </div>
    );
  };

  const performRedirect = () => {
    if (doRedirect) {
      return <Redirect to="/signUp" />;
      // return <Redirect to="/admin/userdashboard" />;
    }
  };

  const signUpForm = () => {
    return (
      <div id="signUp-background">
        {isSignedUp ? (
          <div id="signUp-content-container">
            <div id="signUp-content">
              <div id="signUp-content-inner-container">
                <div id="signUp-content-inner">
                  <div id="signUp-content-inner-img-container">
                    <img id="signUp-content-inner-img" src={logo} alt="" />
                  </div>
                  <div id="signUp-content-inner-heading-container">
                    <h1 id="signUp-content-inner-heading">
                      Support your favorite content creators
                    </h1>
                  </div>
                  <div id="signUp-content-inner-sub-heading-container">
                    <p id="signUp-content-inner-sub-heading">
                      You can support content creators by sending a little
                      hearts. It’s a way of thanking them for making great
                      content.
                    </p>
                  </div>
                </div>
              </div>

              <div id="signUp-content-form-card-container">
                <div id="signUp-content-form-card">
                  <div id="signUp-content-form-card-inner">
                    <div id="signUp-form-card-heading-container">
                      <div id="signUp-form-card-heading">Sign Up</div>
                      <div id="signUp-form-card-sub-heading">
                        New here? Sign up
                      </div>
                    </div>
                    <div id="signUp-form-card-input-container">
                      <form id="signUp-form-card">
                        <div id="signUp-form-card-inputs">
                          <div id="signUp-form-card-username-input-container">
                            <input
                              id="signUp-form-card-username-input"
                              type="text"
                              placeholder="Username"
                              value={userName}
                              onChange={handleChanges("userName")}
                              autoFocus
                            ></input>
                          </div>
                          <div id="signUp-form-card-email-input-container">
                            <input
                              id="signUp-form-card-email-input"
                              type="email"
                              placeholder="Email"
                              value={email}
                              onChange={handleChanges("email")}
                            ></input>
                          </div>
                          <div id="signUp-form-card-password-input-container">
                            <input
                              id="signUp-form-card-password-input"
                              type="password"
                              placeholder="Password"
                              value={password}
                              onChange={handleChanges("password")}
                            ></input>
                          </div>
                        </div>
                        <div id="signUp-form-card-footer">
                          <div id="signUp-form-card-already-user">
                            <a
                              id="signUp-form-card-already-user-text"
                              href="/signIn"
                              // style={{
                              //   fontSize: "13.5px",
                              //   fontWeight: "700",
                              //   color: "black",
                              // }}
                            >
                              Already have an account?
                              <span id="log-in-text">Log in</span>
                            </a>
                          </div>
                          <div id="signUp-form-card-signUp-button-container">
                            <button
                              type="button"
                              onClick={talkToBackend}
                              id="signUp-form-card-signUp-button"
                            >
                              Sign Up
                            </button>
                          </div>
                        </div>
                      </form>
                      {/* <div></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div id="verify-email-background">
            <div id="verify-email-card">
              <div id="verify-email-card-inner">
                <div id="verify-email-card-heading">
                  Please Verify Your Email
                </div>
                <div id="verify-email-card-content">
                  You will be signed up once you verify your account through
                  email.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      // <div>
      // <div>
      //   <div className="main-content">
      //     {/* Navbar */}
      //     <nav className="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
      //       <div className="container px-4">
      //         <a
      //           className="navbar-brand"
      //           style={{ color: "white", position: "static" }}
      //           // href="https://www.creative-tim.com/product/argon-dashboard"
      //           target="_blank"
      //         >
      //           Little Hearts
      //         </a>
      //         <div
      //           className="collapse navbar-collapse"
      //           id="navbar-collapse-main"
      //         >
      //           {/* Collapse header */}
      //           <div className="navbar-collapse-header d-md-none">
      //             <div className="row">
      //               <div className="col-6 collapse-brand">
      //                 {/* <a href="../index.html">Argon</a> */}
      //               </div>
      //               <div className="col-6 collapse-close">
      //                 <button
      //                   type="button"
      //                   className="navbar-toggler"
      //                   data-toggle="collapse"
      //                   data-target="#navbar-collapse-main"
      //                   aria-controls="sidenav-main"
      //                   aria-expanded="false"
      //                   aria-label="Toggle sidenav"
      //                 >
      //                   <span />
      //                   <span />
      //                 </button>
      //               </div>
      //             </div>
      //           </div>
      //           {/* Navbar items */}
      //           <ul className="navbar-nav ml-auto">
      //             <li className="nav-item">
      //               <a
      //                 className="nav-link nav-link-icon"
      //                 href="/signIn"
      //                 // target="_blank"
      //               >
      //                 <i className="ni ni-key-25" />
      //                 {/* <Link to="/signIn"> */}
      //                 <span className="nav-link-inner--text">Sign In</span>
      //                 {/* </Link> */}
      //               </a>
      //             </li>
      //           </ul>
      //         </div>
      //       </div>
      //     </nav>
      //     {/* Header */}
      //     <div className="header bg-gradient-primary py-7 py-lg-6">
      //       <div className="container">
      //         <div className="header-body text-center mb-7">
      //           <div className="row justify-content-center">
      //             <div className="col-lg-5 col-md-6">
      //               <h1 className="text-white">Welcome to Little Hearts!</h1>
      //               <p className="text-lead text-white">Sign Up</p>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //       <div className="separator separator-bottom separator-skew zindex-100">
      //         <svg
      //           x={0}
      //           y={0}
      //           viewBox="0 0 2560 100"
      //           preserveAspectRatio="none"
      //           version="1.1"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <polygon
      //             className="fill-default"
      //             points="2560 0 2560 100 0 100"
      //           />
      //         </svg>
      //       </div>
      //     </div>
      //     {/* Page content */}
      //     <div className="container mt--8 pb-5">
      //       <div className="row justify-content-center">
      //         <div className="col-lg-5 col-md-7">
      //           <div className="card bg-secondary shadow border-0">

      //             {isSignedUp?
      //               <div className="card-body px-lg-5 py-lg-5">
      //               <div className="text-center text-muted mb-4">
      //                 <small>Sign up with these required credentials</small>
      //               </div>
      //               <form role="form">
      //                 <div className="form-group mb-3">
      //                   <div className="input-group input-group-alternative">
      //                     <input
      //                       className="form-control"
      //                       placeholder="Username"
      //                       type="text"
      //                       value={userName}
      //                       onChange={handleChanges("userName")}
      //                       style={{
      //                         color: "black",
      //                         border: "1px solid black",
      //                       }}
      //                       autoFocus
      //                     />
      //                   </div>
      //                 </div>
      //                 <div className="form-group mb-3">
      //                   <div className="input-group input-group-alternative">
      //                     <input
      //                       className="form-control"
      //                       placeholder="Email"
      //                       type="email"
      //                       value={email}
      //                       onChange={handleChanges("email")}
      //                       style={{
      //                         color: "black",
      //                         border: "1px solid black",
      //                       }}
      //                     />
      //                   </div>
      //                 </div>
      //                 <div className="form-group">
      //                   <div className="input-group input-group-alternative">
      //                     <input
      //                       className="form-control"
      //                       placeholder="Password"
      //                       type="password"
      //                       value={password}
      //                       onChange={handleChanges("password")}
      //                       style={{
      //                         color: "black",
      //                         border: "1px solid black",
      //                       }}
      //                     />
      //                   </div>
      //                 </div>
      //                 <div className="custom-control custom-control-alternative custom-checkbox">
      //                   <input
      //                     className="custom-control-input"
      //                     id=" customCheckLogin"
      //                     type="checkbox"
      //                   />
      //                 </div>
      //                 <div className="text-center">
      //                   <button
      //                     type="button"
      //                     className="btn btn-primary my-1"
      //                     onClick={talkToBackend}
      //                   >
      //                     Sign Up
      //                   </button>
      //                 </div>
      //               </form>
      //               </div>
      //               :
      //               <div className="card-body px-lg-5 py-lg-5">
      //                 <h3 style={{color: 'gray',textAlign:'center'}}>You will be signed up once you verify your account through email.</h3>
      //               </div>
      //               }
      //           </div>
      //           <div className="row mt-3">
      //             <div className="col-6">
      //               <a href="/forgotPassword" className="text-light">
      //                 <small>Forgot password?</small>
      //               </a>
      //             </div>
      //             <div className="col-6 text-right">
      //               <a href="/signIn" className="text-light">
      //                 <small>Already have an account?</small>
      //               </a>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   {/* Footer */}
      //   <footer className="py-4">
      //     <div className="container">
      //       <div className="row align-items-center justify-content-xl-between">
      //         <div className="col-xl-6">
      //           <div className="copyright text-center text-xl-left text-muted">
      //             © 2021{" "}
      //             <a
      //               // href="https://www.creative-tim.com/product/argon-dashboard"
      //               className="font-weight-bold ml-1"
      //               target="_blank"
      //             >
      //               Little Hearts
      //             </a>
      //           </div>
      //         </div>
      //         <div className="col-xl-6">
      //           <ul className="nav nav-footer justify-content-center justify-content-xl-end">
      //             <li className="nav-item">
      //               <a
      //                 //   href="https://www.creative-tim.com"
      //                 className="nav-link"
      //                 target="_blank"
      //               >
      //                 Little Hearts
      //               </a>
      //             </li>
      //             <li className="nav-item">
      //               <a
      //                 //   href="https://www.creative-tim.com/presentation"
      //                 className="nav-link"
      //                 target="_blank"
      //               >
      //                 About Us
      //               </a>
      //             </li>
      //           </ul>
      //         </div>
      //       </div>
      //     </div>
      //   </footer>
      // </div>
      // </div>
    );
  };

  return (
    <div>
      {/* {successMessage()} */}
      {errorMessage()}
      {signUpForm()}
      {/* {performRedirect()} */}
      {/* <p>{JSON.stringify(userData)}</p> */}
    </div>
  );
};

export default SignUp;
