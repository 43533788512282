/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { RiArrowRightSLine } from "react-icons/ri";

// reactstrap components
import { Nav, NavLink as ReactstrapNavLink } from "reactstrap";
import {
  BackgroundColorContext,
  backgroundColors,
} from "contexts/BackgroundColorContext";

import heartIcon from "../../assets/img/heart1.png";
import logoMain from "../../assets/icons/logo.png";
import telegram from "../../assets/icons/telegram.png";
import { isSignedIn } from "apiCalls/apiCalls";
import { read_cookie } from "sfcookies";

var ps;

function Sidebar(props) {
  const location = useLocation();
  const sidebarRef = React.useRef(null);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(sidebarRef.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //   }
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // });
  const linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };
  const { routes, rtlActive, logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          target="_blank"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logoMain} alt="react-logo" />
          </div>
        </a>
      );
      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          target="_blank"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={heartIcon} alt="react-logo" />
          </div>
        </Link>
      );
      logoText = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </Link>
      );
    }
  }

  // const [user, setUser] = useState({});
  // const [userName, setUserName] = useState("");

  const user = read_cookie("userInfo");
  let userName = "";

  // useEffect(() => {
  // setUser(read_cookie("userInfo"));
  console.log("sidebar", user);

  // if (user !== undefined) {
  // if (user || user.length) {
  if (user && user.length) {
    userName = user.userName;
    userName = userName.substring(0, 5);

    // console.log("sidebar", userName);

    // setUserName(user.userName);
    // setUserName(userName.substring(0, 5));
  }
  // }, []);

  // user = user.replace(/^"(.*)"$/, "$1");
  // user = JSON.parse(user);
  // console.log("sidebar", user);

  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className="sidebar" data={color} id="sidebar">
          <div
            className="sidebar-wrapper"
            ref={sidebarRef}
            id="sidebar-wrapper"
          >
            <div id="siderbar-content-upper">
              {logoImg !== null || logoText !== null ? (
                <div className="logo">
                  {logoImg}
                  {/* {logoText} */}
                </div>
              ) : null}

              <Nav>
                {routes.map((prop, key) => {
                  console.log("routesssssssss", routes);
                  if (prop.redirect) {
                    return null;
                  } else if (userName === "GUEST") {
                    if (prop.path === "/userdashboard") return null;
                    else if (prop.path === "/dashboard") return null;
                  } else if (
                    prop.path === "/dashboard" &&
                    user.creatorAccount === false
                  ) {
                    return null;
                  } else if (
                    prop.path === "/redeem-hearts" &&
                    (user.isAdmin === undefined || user.isAdmin === false)
                  ) {
                    return null;
                  } else if (
                    prop.path === "/verify-content" &&
                    (user.isAdmin === undefined || user.isAdmin === false)
                  ) {
                    return null;
                  } else if (
                    prop.path === "/request" &&
                    (user.isAdmin === undefined || user.isAdmin === false)
                  ) {
                    return null;
                  }

                  return (
                    <li
                      className={
                        activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                      }
                      key={key}
                    >
                      <NavLink
                        id={
                          activeRoute(prop.layout + prop.path) === "active"
                            ? "sidebarNavLinkActive"
                            : "sidebarNavLink"
                        }
                        class
                        to={prop.layout + prop.path}
                        className="nav-link"
                        activeClassName="active"
                        onClick={() => {
                          props.toggleSidebar();
                        }}
                      >
                        {/* <img
                        id="dashboard-img-icon"
                        src={
                          prop.path === "/userdashboard" &&
                          activeRoute(prop.layout + prop.path) !== "active"
                            ? user_dashboard
                            : prop.path === "/userdashboard" &&
                              activeRoute(prop.layout + prop.path) === "active"
                            ? user_dashboard_active
                            : prop.path === "/dashboard" &&
                              activeRoute(prop.layout + prop.path) !== "active"
                            ? creator_dashboard
                            : prop.path === "/dashboard" &&
                              activeRoute(prop.layout + prop.path) === "active"
                            ? creator_dashboard_active
                            : prop.path === "/user-profile"
                            ? user_profile
                            : prop.path === "/redeem-hearts" &&
                              activeRoute(prop.layout + prop.path) !== "active"
                            ? user_dashboard
                            : prop.path === "/redeem-hearts" &&
                              activeRoute(prop.layout + prop.path) === "active"
                            ? user_dashboard_active
                            : prop.path === "/verify-content" &&
                              activeRoute(prop.layout + prop.path) !== "active"
                            ? user_dashboard
                            : prop.path === "/verify-content" &&
                              activeRoute(prop.layout + prop.path) === "active"
                            ? user_dashboard_active
                            : prop.path === "/request" &&
                              activeRoute(prop.layout + prop.path) !== "active"
                            ? user_dashboard
                            : prop.path === "/request" &&
                              activeRoute(prop.layout + prop.path) === "active"
                            ? user_dashboard_active
                            : ""
                        }
                        alt="img"
                      /> */}
                        <img
                          src={
                            window.location.origin +
                              activeRoute(prop.layout + prop.path) ==
                            "active"
                              ? prop.active_img_src
                              : activeRoute(prop.layout + prop.path) != "active"
                              ? prop.img_src
                              : prop.active_img_src
                          }
                          alt="img"
                          id={
                            activeRoute(prop.layout + prop.path) != "active"
                              ? "dashboard-img-icon"
                              : "dashboard-img-icon-active"
                          }
                        />
                        {console.log(
                          "pathhhhhhhhhh",
                          activeRoute(prop.layout + prop.path),
                          window.location.origin +
                            activeRoute(prop.layout + prop.path) ===
                            "active"
                            ? prop.active_img_src
                            : prop.img_src
                        )}
                        {/* <i className={prop.icon} /> */}
                        {/* <p>{rtlActive ? prop.rtlName : prop.name}</p> */}
                        <p style={{ fontSize: "13px" }}>{prop.name} </p>
                        {activeRoute(prop.layout + prop.path) === "active" && (
                          <RiArrowRightSLine id="right-arrow-icon" />
                        )}
                      </NavLink>
                    </li>
                  );
                })}
                {/* <li className="active-pro">
                <ReactstrapNavLink href="https://www.creative-tim.com/product/black-dashboard-pro-react?ref=bdr-user-archive-sidebar-upgrade-pro">
                  <i className="tim-icons icon-spaceship" />
                  <p>Upgrade to PRO</p>
                </ReactstrapNavLink>
              </li> */}
              </Nav>
            </div>
            <div id="sidebar-content-lower">
              <h4>We're Here To help!</h4>
              <p>
                <a
                  href="mailto:support@littlehearts.xyz"
                  // target="_blank"
                  // rel="noopener noreferrer"
                >
                  support@littlehearts.xyz
                </a>
              </p>
              <div>
                <a
                  href="https://web.telegram.org/k/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={telegram} alt="telegram" />
                </a>
                <p>Get in touch</p>
              </div>
            </div>

            {/* <div>
              <img src={telegram} alt="telegram" />
            </div> */}
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

Sidebar.defaultProps = {
  rtlActive: false,
  routes: [{}],
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
