import React, { useEffect, useState } from "react";
import { read_cookie, bake_cookie } from "sfcookies";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
} from "reactstrap";
import Loading from "./Loading";
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import {
  PaymentAPI,
  LittleHeartsAPI,
  razorpayID,
  minLHCount,
  maxLHCount,
} from "../backend";

const AddHearts = (props) => {
  const [unitPrice, setUnitPrice] = useState();
  // Calling the API from routes/storage1.js where name of the variable will be send through params
  // In response we will be getting the full object containing the key data in which one content key is present, our whole storage1 details will be stored.
  // And accordingly we will get the variable's value from the response.
  useEffect(() => {
    axios.get(`${LittleHeartsAPI}/storage1/unitPrice`).then((response) => {
      console.log(response);
      if (response.data.content != null) {
        console.log(response.data.content.codeValue);
        setUnitPrice(response.data.content.codeValue);
      }
    });
  }, []);

  /* New Add hearts Logic*/
  const [pendingHearts, setPendingHearts] = useState();
  const [pendingCreators, setPendingCreators] = useState();
  const [creatordata, setcreatordata] = useState([]);
  const [update, setupdate] = useState(false);
  const [transactionID, setTransactionID] = useState("");
  const [totalPayment, setTotalPayment] = useState();
  const [load, setload] = useState(true);
  const [transactionDate, setTransactionDate] = useState(0);
  const [flashCardDisplay, setFlashCardDisplay] = useState(false);
  const [errorFlashCardDisplay, setErrorFlashCardDisplay] = useState(false);
  const [secondFlashCardDisplay, setSecondFlashCardDisplay] = useState(false);
  const [totalHeartsBought, SetTotalHeartsBought] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const d = new Date(Date.now());

  // document.addEventListener("click", (event) => {
  //   console.log(
  //     "clickeedddddddddddddddddddddddd",
  //     event.target.classList,
  //     event.target.id
  //   );
  //   if (
  //     !event.target.id ||
  //     (event.target.id !== "flash-card" &&
  //       event.target.id !== "flash-card-content" &&
  //       event.target.id !== "flash-card-tick-icon-img-container" &&
  //       event.target.id !== "flash-card-tick-icon-img" &&
  //       event.target.id !== "flash-card-heading-msg" &&
  //       event.target.id !== "flash-card-ammount" &&
  //       event.target.id !== "flash-card-date" &&
  //       event.target.id !== "flash-card-ammount-inner-text" &&
  //       event.target.id !== "flash-card-ammount-inner" &&
  //       event.target.id !== "flash-card-date-inner-text" &&
  //       event.target.id !== "flash-card-date-inner" &&
  //       event.target.id !== "flash-card-transactionId-inner-text" &&
  //       event.target.id !== "flash-card-transactionId-inner" &&
  //       event.target.id !== "flash-card-transactionId" &&
  //       event.target.id !== "error-flash-card" &&
  //       event.target.id !== "error-flash-card-content" &&
  //       event.target.id !== "error-flash-card-tick-icon-img-container" &&
  //       event.target.id !== "error-flash-card-tick-icon-img" &&
  //       event.target.id !== "error-flash-card-heading-msg" &&
  //       event.target.id !== "error-flash-card-subheading-msg" &&
  //       event.target.id !== "second-flash-card" &&
  //       event.target.id !== "second-flash-card-content" &&
  //       event.target.id !== "second-flash-card-tick-icon-img-container" &&
  //       event.target.id !== "second-flash-card-tick-icon-img" &&
  //       event.target.id !== "second-flash-card-heading-msg" &&
  //       event.target.id !== "second-flash-card-close-button")
  //   ) {
  //     setFlashCardDisplay(false);
  //     setErrorFlashCardDisplay(false);
  //     setSecondFlashCardDisplay(false);
  //   }
  // });

  document.addEventListener("click", (event) => {
    console.log(
      "clickeedddddddddddddddddddddddd",
      event.target.classList,
      event.target.id
    );
    if (!event.target.id || event.target.id === "flash-card-background") {
      setFlashCardDisplay(false);
    } else if (
      !event.target.id ||
      event.target.id === "error-flash-card-background"
    ) {
      setErrorFlashCardDisplay(false);
    } else if (
      !event.target.id ||
      event.target.id === "second-flash-card-background"
    ) {
      setSecondFlashCardDisplay(false);
    }
  });

  useEffect(() => {
    async function temp() {
      let userInfo = await read_cookie("userInfo");
      axios
        .post(`${PaymentAPI}/payment/accumulate?userid=${userInfo._id}`)
        .then((response) => {
          console.log(response.data.result2);
          let result = response.data.result2;
          let heartCount = 0;
          let creatorCount = 0;
          let resultset = [];
          let temp = [];
          result.forEach((x) => {
            heartCount += x.totalHeartsGiven;
            if (!resultset[x.toContentURL]) {
              creatorCount++;
              let obj = {
                url: x.toContentURL,
                heartsgiven: x.totalHeartsGiven,
              };
              temp.push(obj);
            } else {
              temp.map((ele) => {
                if (ele.url === x.toContentURL)
                  ele.heartsgiven += x.totalHeartsGiven;
              });
            }

            resultset[x.toContentURL] = "present";
          });

          setcreatordata(temp);
          console.log(temp);
          setload(false);
          setPendingCreators(creatorCount);
          setPendingHearts(heartCount);
        });
    }
    temp();
  }, [update]);

  /* Temp Add hearts Logic*/

  const [amountPossible, setAmountPossible] = useState([minLHCount]);
  useEffect(() => {
    // For ranging the count of hearts from minimum value to maximum value in the gap of minimum value
    for (let i = minLHCount * 2; i <= maxLHCount; i += minLHCount) {
      setAmountPossible((amountPossible) => [...amountPossible, i]);
    }
  }, []);

  let finalAmount;
  let heartBought;
  // unit price is coming after getting set in the useEffect when the component is rendered.
  // When the no.of hearts will be enteredthen total cost will be calculated on change of that.
  function changeInput() {
    let hearts = document.getElementById("hearts");
    let unitprice = document.getElementById("unitprice");
    let totalcost = document.getElementById("totalcost");

    totalcost.value = hearts.value * unitprice.value;
    finalAmount = totalcost.value * 100;
    heartBought = hearts.value;
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // New payment method
  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    let result = await axios.post(
      `${PaymentAPI}/payment/orders?amount=${pendingHearts * unitPrice * 100}`
    );

    if (!result) {
      alert("Internal server error. Razorpay does not respond!!");
      return;
    }

    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: razorpayID, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Little Hearts",
      description: "Test Transaction",
      image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          creatorinfo: creatordata,
        };
        // console.log(creatordata)
        let userid = await read_cookie("userInfo");
        let result = await axios.post(
          `${PaymentAPI}/payment/success?heartBought=${pendingHearts}&userid=${userid._id}&unitPrice=${unitPrice}`,
          data
        );

        if (result.data.msg === "success") {
          setTotalPayment(pendingHearts * unitPrice);
          setTransactionID(result.data.paymentId);
          SetTotalHeartsBought(result.data.heartBought);
          setTotalRewards(result.data.rewards);
          const cd = d.toString();
          console.log(cd);
          setTransactionDate(cd.slice(16, 24) + ", " + cd.slice(4, 15));
          setFlashCardDisplay(true);
          // alert(
          //   `Payment Successful! for ${result.data.heartBought} Hearts .${result.data.rewards} Hearts credited to your account. \nOrder Id: ${result.data.orderId} \nPayment Id: ${result.data.paymentId}`
          // );
          setupdate(true);
        } else {
          setErrorFlashCardDisplay(true);
          setErrorMsg(result.data.msg);
          // alert(result.data.msg);
        }
        return;
      },
      notes: {
        address: "Example Corporate Office",
      },
      theme: {
        color: "red",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  // Old add hearts function

  //   async function displayRazorpay() {
  //     const res = await loadScript(
  //       'https://checkout.razorpay.com/v1/checkout.js'
  //     );

  //     if (!res) {
  //       alert('Razorpay SDK failed to load. Are you online?');
  //       return;
  //     }
  //     let result;

  //     if(finalAmount){
  //         result = await axios.post(`${PaymentAPI}/payment/orders?amount=${finalAmount}`);
  //     }
  //     else{
  //         // Initial Amount = amountPossible[0] * unitPrice * 100
  //         result = await axios.post(`${PaymentAPI}/payment/orders?amount=${amountPossible[0] * unitPrice * 100}`);
  //     }

  //     if (!result) {
  //       alert('Internal server error. Razorpay does not respond!!');
  //       return;
  //     }

  //     const { amount, id: order_id, currency } = result.data;

  //     const options = {
  //       key: razorpayID, // Enter the Key ID generated from the Dashboard
  //       amount: amount.toString(),
  //       currency: currency,
  //       name: 'Little Hearts',
  //       description: 'Test Transaction',
  //       image: { logo },
  //       order_id: order_id,
  //       handler: async function (response) {
  //         const data = {
  //           orderCreationId: order_id,
  //           razorpayPaymentId: response.razorpay_payment_id,
  //           razorpayOrderId: response.razorpay_order_id,
  //           razorpaySignature: response.razorpay_signature,
  //         };

  //         let userid = await read_cookie('userInfo');
  //         let result;
  //         if(heartBought)
  //             result = await axios.post(`${PaymentAPI}/payment/success?heartBought=${heartBought}&userid=${userid._id}&unitPrice=${unitPrice}`, data);
  //         else{
  //             heartBought = amountPossible[0];
  //             result = await axios.post(`${PaymentAPI}/payment/success?heartBought=${amountPossible[0]}&userid=${userid._id}&unitPrice=${unitPrice}`, data);
  //         }

  //         if(result.data.msg === 'success'){
  //             alert(`Payment Successful! ${heartBought} Hearts added! \nOrder Id: ${result.data.orderId} \nPayment Id: ${result.data.paymentId}`)
  //         }
  //         else{
  //             alert(result.data.msg);
  //         }
  //         return;
  //       },
  //       notes: {
  //         address: 'Example Corporate Office',
  //       },
  //       theme: {
  //         color: 'red',
  //       },
  //     };

  //     const paymentObject = new window.Razorpay(options);
  //     paymentObject.open();
  // }

  return (
    <div>
      {/* flash card for successful transaction */}
      <div
        style={{ display: flashCardDisplay ? "flex" : "none" }}
        id="flash-card-background"
      >
        <div id="flash-card">
          <div id="flash-card-content">
            <div id="flash-card-tick-icon-img-container">
              <img
                id="flash-card-tick-icon-img"
                src={window.location.origin + "/icons/tick_large.png"}
                alt=""
              />
            </div>
            <div id="flash-card-heading-msg">Payment Successful!</div>
            <div
              id="flash-card-ammount"
              className="flash-card-inner-text-container"
            >
              <div
                id="flash-card-ammount-inner-text"
                className="flash-card-inner-text"
              >
                Ammount
              </div>
              <div id="flash-card-ammount-inner" className="flash-card-inner">
                ₹{totalPayment}
              </div>
            </div>
            <div
              id="flash-card-date"
              className="flash-card-inner-text-container"
            >
              <div
                id="flash-card-date-inner-text"
                className="flash-card-inner-text"
              >
                Date and time
              </div>
              <div id="flash-card-date-inner" className="flash-card-inner">
                {transactionDate}
                {/* 00:41, 20<sup>th</sup> Oct 2022 */}
              </div>
            </div>
            <div
              id="flash-card-transactionId"
              className="flash-card-inner-text-container"
            >
              <div
                id="flash-card-transactionId-inner-text"
                className="flash-card-inner-text"
              >
                Txn ID
              </div>
              <div
                id="flash-card-transactionId-inner"
                className="flash-card-inner"
              >
                {transactionID}
                {/* 881GGGA18888450 */}
              </div>
            </div>
            <div
              id="flash-card-close-button"
              onClick={() => {
                setFlashCardDisplay(false);
                setSecondFlashCardDisplay(true);
              }}
            >
              Got it
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: secondFlashCardDisplay ? "flex" : "none" }}
        id="second-flash-card-background"
      >
        <div id="second-flash-card">
          <div id="second-flash-card-content">
            <div id="second-flash-card-tick-icon-img-container">
              <img
                id="second-flash-card-tick-icon-img"
                src={window.location.origin + "/icons/tick_large.png"}
                alt=""
              />
            </div>
            <div id="second-flash-card-heading-msg">Payment Successful!</div>
            <div id="second-flash-card-content-msg-container">
              <div className="second-flash-card-content-msg">
                Payment Successful! for {totalHeartsBought} Hearts.
              </div>
              <div className="second-flash-card-content-msg">
                {totalRewards} Hearts credited to your account.
              </div>
            </div>
            <div
              id="second-flash-card-close-button"
              onClick={() => {
                setSecondFlashCardDisplay(false);
              }}
            >
              Got it
            </div>
          </div>
        </div>
      </div>
      {/* flash card for error in transaciton  */}
      <div
        style={{ display: errorFlashCardDisplay ? "flex" : "none" }}
        id="error-flash-card-background"
      >
        <div id="error-flash-card">
          <div id="error-flash-card-content">
            <div id="error-flash-card-tick-icon-img-container">
              <img
                id="error-flash-card-tick-icon-img"
                src={window.location.origin + "/icons/info_large.png"}
                alt=""
              />
            </div>
            <div id="error-flash-card-heading-msg">Transaction failed</div>
            <div id="error-flash-card-subheading-msg">Please try again</div>
            <div
              id="error-flash-card-close-button"
              onClick={() => {
                setErrorFlashCardDisplay(false);
              }}
            >
              Got it
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <Card className="card-custom">
          <CardHeader>
            <CardTitle tag="h4" className="card-title">
              <div id="addHearts-card-title">
                <div className="card-title-heading">Pending Payment</div>
                <div className="addHearts-card-title-count">
                  <div className="addHearts-card-title-count-msg">
                    You are Paying for {pendingHearts} hearts
                  </div>
                  <div
                    className="addHearts-payment-button"
                    onClick={() => {
                      displayRazorpay();
                    }}
                  >
                    Pay ₹{pendingHearts * unitPrice}
                  </div>
                </div>
              </div>
              {/* <h4>Pending Payment</h4> */}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {/* <Form id="addHearts-card-main">
            <FormGroup row>
              <Label for="hearts" sm={6}>
                <center>No. of Hearts pending for payment</center>
              </Label>
              <Col sm={6}>
                <Input
                  id="totalcost"
                  name="totalcost"
                  placeholder="0"
                  type="number"
                  style={{ color: "white" }}
                  value={pendingHearts}
                  readOnly
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="hearts" sm={6}>
                <center>No. of Creators Regd.</center>
              </Label>
              <Col sm={6}>
                <Input
                  id="unitprice"
                  name="unitprice"
                  placeholder="0"
                  type="number"
                  value={pendingCreators}
                  style={{ color: "white" }}
                  readOnly
                />
                <p
                  onClick={() => props.setShowContent(2)}
                  style={{
                    marginTop: "5px",
                    marginLeft: "20px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  *Click here for details
                </p>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="unitprice" sm={6}>
                <center>Unit Price</center>
              </Label>
              <Col sm={6}>
                <Input
                  id="unitprice"
                  name="unitprice"
                  placeholder="0"
                  type="number"
                  value={unitPrice}
                  style={{ color: "white" }}
                  readOnly
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="totalcost" sm={6}>
                <center>Total Cost (e.g. INR)</center>
              </Label>
              <Col sm={6}>
                <Input
                  id="totalcost"
                  name="totalcost"
                  placeholder="0"
                  type="number"
                  style={{ color: "white" }}
                  value={pendingHearts * unitPrice}
                  readOnly
                />
              </Col>
            </FormGroup>
            <center>
              <Button
                onClick={() => {
                  displayRazorpay();
                }}
              >
                Click to Proceed
              </Button>
            </center>
          </Form> */}
            <div id="addHearts-card-main">
              {load && <Loading />}
              <div className="addHearts-container">
                <div className="addHearts-heading">
                  No. of Hearts pending for payment
                </div>
                <div className="addHearts-main-count-container">
                  <Input
                    id="totalcost"
                    name="totalcost"
                    placeholder=""
                    type="number"
                    style={{ color: "white" }}
                    value={pendingHearts}
                    readOnly
                    className="addHearts-main-count"
                  />
                </div>
              </div>
              <div className="addHearts-container">
                <div className="addHerats-heading-subheading">
                  <div className="addHearts-heading">No. of Creators Regd.</div>
                  <div
                    className="addHearts-subheading"
                    onClick={() => props.setShowContent(2)}
                  >
                    View Details
                  </div>
                </div>
                <div className="addHearts-main-count-container">
                  <Input
                    id="unitprice"
                    name="unitprice"
                    placeholder=""
                    type="number"
                    value={pendingCreators}
                    style={{ color: "white" }}
                    readOnly
                    className="addHearts-main-count"
                  />
                  {/* <p
                  onClick={() => props.setShowContent(2)}
                  style={{
                    marginTop: "5px",
                    marginLeft: "20px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  *Click here for details
                </p> */}
                </div>
              </div>
              <div className="addHearts-container">
                <div className="addHearts-heading">Unit Price</div>
                <div className="addHearts-main-count-container">
                  <Input
                    id="unitprice"
                    name="unitprice"
                    placeholder=""
                    type="number"
                    value={unitPrice}
                    style={{ color: "white" }}
                    readOnly
                    className="addHearts-main-count"
                  />
                </div>
              </div>
              <div className="addHearts-container">
                <div className="addHearts-heading">Total Cost (e.g. INR)</div>
                <div className="addHearts-main-count-container">
                  <Input
                    id="totalcost"
                    name="totalcost"
                    placeholder=""
                    type="number"
                    style={{ color: "white" }}
                    value={pendingHearts * unitPrice}
                    readOnly
                    className="addHearts-main-count"
                  />
                </div>
              </div>
              {/* <div>
              <Button
                onClick={() => {
                  displayRazorpay();
                }}
              >
                Click to Proceed
              </Button>
            </div> */}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddHearts;
