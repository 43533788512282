import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { read_cookie, bake_cookie } from "sfcookies";
import cookie from "react-cookie";
import { Redirect } from "react-router-dom";
import { LittleHeartsAPI } from "backend";
const VerifyEmail = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const q = queryParams.get("q");
  const cookie_key1 = "userToken";
  const cookie_key2 = "userInfo";
  // alert(q);
  useEffect(async () => {
    console.log(q);
    const guestInfo = cookie.load("userInfoLH");
    console.log("verifying");
    console.log(guestInfo);
    await axios
      .post(`${LittleHeartsAPI}/verifyEmail?q=${q}`)
      .then(async (res) => {
        console.log("q send to backend", res);
        // await bake_cookie(cookie_key1, res.data.token);
        // await bake_cookie(cookie_key2, res.data.user);
      })
      .catch((err) => {
        console.error("Error Axios", err);
      });
  }, []);
  const performRedirect = () => {
    // return <Redirect to="/admin/userdashboard" />;

    setTimeout(() => {
      // <Redirect to="/admin/userdashboard" />
      alert("Your Email is verified, You can sign in now!");
      document.location.href = "/";
    }, 1000);
  };

  const reloadUsingLocationHash = () => {
    if (window.localStorage) {
      // If there is no item as 'reload'
      // in localstorage then create one &
      // reload the page
      if (!localStorage.getItem("reload")) {
        localStorage["reload"] = true;
        window.location.reload();
      } else {
        // If there exists a 'reload' item
        // then clear the 'reload' item in
        // local storage
        localStorage.removeItem("reload");
      }
    }
  };

  return (
    <div>
      <div id="verify-email-backend-background">
        <div id="verify-email-backend-card">
          <div id="verify-email-backend-card-inner">
            <div id="verify-email-backend-card-heading">
              Please wait your Email is getting verified.
            </div>
            {/* <div id="verify-email-card-content">
                  You will be signed up once you verify your account through
                  email.
                </div> */}
          </div>
        </div>
      </div>
      <div>
        <h4>Please wait your Email is getting verified.</h4>
      </div>
      {/* {reloadUsingLocationHash()} */}
      {performRedirect()}
    </div>
  );
};

export default VerifyEmail;
