import React, { useEffect, useState } from "react";
import { read_cookie, bake_cookie } from "sfcookies";
import { FiRefreshCcw } from "react-icons/fi";
import { GiHearts, GiMoneyStack } from "react-icons/gi";

// reactstrap components

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  ModalBody,
  NavbarToggler,
  ModalHeader,
} from "reactstrap";

import axios from "axios";
import { PaymentAPI, LittleHeartsAPI } from "../backend";
const Request = () => {
  const [search, setsearch] = useState("All");
  const toggle = () => setShouldDisplayModal(!shouldDisplayModal);
  const [shouldDisplayModal, setShouldDisplayModal] = React.useState(false);
  const [arr, setarr] = useState([]);
  const [remark, setremark] = useState();
  const [data, setdata] = useState({
    url: "",
    email: "",
    description: "",
    requestDate: "",
    status: "",
    remarks: "",
    review_date: "",
    _id: "",
  });
  let updatedData;
  function handleSubmit(para) {
    console.log(remark);
    axios
      .post(
        `${LittleHeartsAPI}/verifyclaimadmin?id=${data._id}&review=${para}&remarks=${remark}`
      )
      .then((response) => {
        console.log("new msg", response.data.message);
        alert(response.data.message);
        setRefreshState((t) => !t);
      });

    setShouldDisplayModal(false);
  }
  // {
  //   "status":"Pending",
  //   "email":"someone1@email.com",
  //   "url":"https://tempmail.com/",
  //   "date":"2021-05-01",
  //   "description":"Need it for personal use"
  // },
  // {
  //   "status":"Approved",
  //   "email":"someone2@email.com",
  //   "url":"https://tempmail.com/",
  //   "date":"2021-05-01",
  //   "description":"Need it for personal use"
  // },
  // {
  //   "status":"Pending",
  //   "email":"someone3@email.com",
  //   "url":"https://tempmail.com/",
  //   "date":"2021-05-01",
  //   "description":"Need it for personal use"
  // },
  // {
  //   "status":"Pending",
  //   "email":"someone4@email.com",
  //   "url":"https://tempmail.com/",
  //   "date":"2021-05-01",
  //   "description":"Need it for personal use"
  // },
  // {
  //   "status":"Pending",
  //   "email":"someone5@email.com",
  //   "url":"https://tempmail.com/",
  //   "date":"2021-05-01",
  //   "description":"Need it for personal use"
  // },
  // {
  //   "status":"Approved",
  //   "email":"someone@email.com",
  //   "url":"https://tempmail.com/",
  //   "date":"2021-05-01",
  //   "description":"Need it for personal use"
  // },
  // {
  //   "status":"Rejected",
  //   "email":"someone@email.com",
  //   "url":"https://tempmail.com/",
  //   "date":"2021-05-01",
  //   "description":"Need it for personal use"
  // },
  // {
  //   "status":"Approved",
  //   "email":"someone@email.com",
  //   "url":"https://tempmail.com/",
  //   "date":"2021-05-01",
  //   "description":"Need it for personal use"
  // },
  // {
  //   "status":"Pending",
  //   "email":"someone@email.com",
  //   "url":"https://tempmail.com/",
  //   "date":"2021-05-01",
  //   "description":"Need it for personal use"
  // },
  // {
  //   "status":"Rejected",
  //   "email":"someone@email.com",
  //   "url":"https://tempmail.com/",
  //   "date":"2021-05-01",
  //   "description":"Need it for personal use"
  // }
  // ]);
  const [refreshState, setRefreshState] = useState(true);
  // Calling the API from routes/transaction.js where id of the user will be send through params
  // In response we will be getting the full object containing the key data in which one transaction key is present, our whole transaction details will be stored.
  // And accordingly we will get the transaction array from the response.
  useEffect(async () => {
    let userInfo = await read_cookie("userInfo");
    console.log(userInfo._id);
    axios.get(`${LittleHeartsAPI}/getallclaimrequests`).then((response) => {
      if (response.data.arr != null) {
        console.log(response.data.arr);
        setarr(response.data.arr);
        updatedData = response.data.arr;
      }
    });
  }, [refreshState]);
  const handlesub = () => {
    let temp = [];
    updatedData = arr.filter((object) => {
      return object.status === search || search === "All";
    });
  };
  const handleChange1 = (e) => {
    setremark(e.target.value);
  };
  const handleChange = (e) => {
    setsearch(e.target.value);
  };
  const handleview = (idx) => {
    setdata({
      ...data,
      url: updatedData[idx].url,
      email: updatedData[idx].requestByEmail,
      description: updatedData[idx].description,
      requestDate: updatedData[idx].claim_date.slice(0, 10),
      status: updatedData[idx].status,
      remarks: updatedData[idx].remarks,
      review_date: updatedData[idx].review_date,
      _id: updatedData[idx]._id,
    });
    setShouldDisplayModal(true);
  };
  return (
    <div className="content">
      <h1 className="dashboardTitle">Claim Approval</h1>
      <Modal isOpen={shouldDisplayModal} toggle={toggle} backdrop={true}>
        <ModalHeader
          style={{
            justifyContent: "center",
            // fontWeight: "900",
            // fontSize: "150px",
          }}
        >
          <div className="modal-header">Details</div>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              marginLeft: "50px",
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontWeight: "700" }}>Email</div>
            <div style={{ width: "200px" }}>{data.email}</div>
          </div>
          <div
            style={{
              marginLeft: "50px",
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontWeight: "700" }}>URL</div>
            <div
              style={{
                width: "250px",
                maxWidth: "250px",
                // wordWrap: "break-word",
                marginLeft: "135px",
                // overflowWrap: "break-word",
                wordBreak: "break-all",
              }}
            >
              {data.url}
            </div>
          </div>
          <div
            style={{
              marginLeft: "50px",
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontWeight: "700" }}>Description </div>
            <div style={{ width: "200px" }}>{data.description}</div>
          </div>
          <div
            style={{
              marginLeft: "50px",
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontWeight: "700" }}>Request Date</div>
            <div style={{ width: "200px" }}>{data.requestDate}</div>
          </div>
          <div
            style={{
              marginLeft: "50px",
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontWeight: "700" }}>Status</div>
            <div style={{ width: "200px" }}>{data.status}</div>
          </div>
          {/* <div>URL: {data.url}</div>
          <div>Description: {data.description}</div>
          <div>Request Date: {data.requestDate}</div>
          <div>Status: {data.status}</div> */}

          {data.status !== "Approved" && data.status !== "Rejected" && (
            <label for="textarea">Remarks:</label>
          )}
          {data.status !== "Approved" && data.status !== "Rejected" && (
            <Input
              placeholder="something"
              type="text"
              onChange={handleChange1}
              style={{ color: "black", margin: " 0px 10px 10px 0px" }}
            />
          )}

          {data.status !== "Approved" && data.status !== "Rejected" && (
            <Button
              style={{ float: "left" }}
              className="btn-fill"
              color="primary"
              onClick={() => handleSubmit("Approved")}
            >
              Accept
            </Button>
          )}
          {(data.status === "Approved" || data.status === "Rejected") &&
            data.remarks != "undefined" && <div>Remarks:{data.remarks}</div>}
          {(data.status === "Approved" || data.status === "Rejected") &&
            data.review_date && (
              <div
                style={{
                  marginLeft: "50px",
                  width: "80%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontWeight: "700" }}>Review Date</div>
                <div
                  style={{
                    width: "200px",
                  }}
                >
                  {data.review_date.slice(0, 10)}
                </div>
              </div>
            )}
          {data.status !== "Approved" && data.status !== "Rejected" && (
            <Button
              style={{ float: "right" }}
              onClick={() => handleSubmit("Rejected")}
            >
              Reject
            </Button>
          )}
        </ModalBody>
      </Modal>
      <Col lg="11" md="12" className="card-position">
        <div class="mt-3">
          <Card className="card-custom">
            <CardHeader
              className="d-flex flex-row justify-content-between"
              // style={{ display: "flex", alignItems: "center" }}
            >
              <Input
                value={search}
                onChange={handleChange}
                id="hearts"
                type="select"
                name="hearts"
                style={{
                  color: "white",
                  width: "150px",
                  borderRadius: "1.2rem",
                  marginLeft: "15px",
                }}
              >
                <option value="All">All</option>
                <option value="Approved">Approved</option>
                <option value="Rejected">Rejected</option>
                <option value="Pending">Pending</option>
              </Input>
              <h4
                style={{ cursor: "pointer", marginRight: "15px" }}
                onClick={() => setRefreshState((refreshState) => !refreshState)}
              >
                <div className="refresh-container">
                  <div className="refresh-icon-img-container">
                    <img
                      className="refresh-icon-img"
                      src={window.location.origin + "/icons/refresh (1).png"}
                      alt=""
                    />
                  </div>
                  <div className="refresh-msg">Refresh</div>
                </div>
                {/* <FiRefreshCcw /> */}
              </h4>
              <button hidden="true" onClick={handlesub()}></button>
              {/* <CardTitle tag="h4" style={{textAlign: "center",width:'100%'}}>
                 History
                </CardTitle> */}
            </CardHeader>
            <CardBody>
              <Table id="claimApproval-header">
                {/* className="tablesorter" responsive */}
                <thead className="text-primary">
                  <tr id="claimApproval-top-row">
                    <th className="text-center">Email</th>
                    <th className="text-center">Url</th>
                    <th className="text-center">Request Date</th>
                    <th className="text-center">Status</th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {[...updatedData].map((object, idx) => {
                    return (
                      <tr>
                        {/*According to code value ="Purchase" or "Spent" columns' data will be shown in the table  */}
                        <td className="text-center claim-approval-columns">
                          {object.requestByEmail}
                        </td>
                        <td className="text-center claim-approval-columns">
                          {object.url}
                        </td>
                        <td className="text-center claim-approval-columns-end">
                          {object.claim_date.slice(0, 10)}
                        </td>
                        <td
                          className="text-center claim-approval-columns-end"
                          id={
                            object.status === "Approved"
                              ? "request-approved-msg"
                              : object.status === "Pending"
                              ? "request-pending-msg"
                              : object.status === "Rejected"
                              ? "request-rejected-msg"
                              : "request-msg"
                          }
                        >
                          {object.status}
                        </td>
                        <td className="claim-approval-columns-end">
                          <Button
                            id="claim-approval-columns-button"
                            onClick={() => handleview(idx)}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      </Col>
    </div>
  );
};

export default Request;
