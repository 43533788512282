/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { getUser } from "apiCalls/apiCalls";
import { isSignedIn } from "apiCalls/apiCalls";
import { bake_cookie, read_cookie, delete_cookie } from "sfcookies";
import locations from "locations";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { useHistory } from "react-router";
import { updateGuestAcc2 } from "apiCalls/apiCalls";
import { data } from "jquery";

import axios from "axios";
import { PaymentAPI, LittleHeartsAPI } from "../backend";
function UserProfile() {
  // const { user, token } = isSignedIn();
  const user = read_cookie("userInfo");
  const token = read_cookie("userToken");

  const [userName, setUserName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [region, setregion] = useState(user.region);
  const [visible, setVisible] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertText, setAlertText] = useState("");
  let prev_timezone;
  const history = useHistory();

  useEffect(() => {
    if (!user || !token) {
      console.log("HERE");
      return history.push("/");
    }

    getUser(token, user._id).then((data) => {
      if (data.error) {
        console.log("Error", data.error);
      } else {
        // console.log(data);
        setUserName(data.userName);
        setEmail(data.email);
        setDisplayName(data.displayName ? data.displayName : "");
        setAge(data.age ? data.age : "");
        setGender(data.gender ? data.gender : "");
        setregion(data.region ? data.region : "");
        prev_timezone = data.region;
        // console.log(data.region)
      }
    });
  }, []);

  const updateInfo = () => {
    console.log(age);
    if (age !== "" && age <= 0) {
      setVisible(false);
      setAlertColor("danger");
      setAlertText("Age cannot be negative or zero");
      setVisible(true);
    } else if (age === "" || age > 0) {
      if (gender === "M" || gender === "F" || gender === "") {
        if (prev_timezone !== region) {
          console.log(user._id);
          axios
            .post(`${LittleHeartsAPI}/updatezone?tz=${region}&id=${user._id}`)
            .then((response) => {
              console.log(response.message);
              // if(response.message!="Success"){
              // console.log(response.data.transaction);
              // }
            });
        }
        let body = {
          id: user._id,
          user: {
            userName: userName,
            email: email,
            displayName: displayName,
            age: age,
            gender: gender,
            region: region,
          },
        };

        updateGuestAcc2(body)
          .then((data) => {
            if (data.error) {
              console.log("Error", data.error);

              setVisible(false);
              setAlertColor("danger");
              setAlertText(data.error);
              setVisible(true);
            } else {
              bake_cookie("userInfo", data);

              setVisible(false);
              setAlertColor("success");
              setAlertText("Details updated successfully!");
              setVisible(true);
            }
          })
          .catch(console.log("Creator account updated"));
      } else {
        setVisible(false);
        setAlertColor("danger");
        setAlertText("Gender should me M or F!");
        setVisible(true);
      }
    }
  };

  return (
    <>
      {console.log("dataaaaaaaaaa", data, "userrrrrrrrrrrrrrrrrr", user)}
      <div className="content">
        <h1 className="dashboardTitle"> User Profile</h1>
        <Row style={{ width: "80%", position: "fixed", top: "8%", zIndex: 15 }}>
          <Col className="ml-auto mr-auto" md="6">
            <Alert
              color={alertColor}
              isOpen={visible}
              toggle={() => {
                setVisible(false);
              }}
            >
              <span>{alertText}</span>
            </Alert>
          </Col>
        </Row>
        <Row className="card-position">
          <Col md="9">
            <Card className="card-custom">
              <CardHeader className="card-title">
                <div className="edit-profile-card-title">
                  <div className="card-title-heading">Edit Profile</div>
                  <div
                    className="edit-profile-save-button"
                    onClick={updateInfo}
                    type="submit"
                  >
                    Save
                  </div>
                </div>
                {/* <h5 className="edit-profile-card-title title">Edit Profile</h5> */}
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="user-profile-input-container-row">
                    <div className="user-profile-input-container-column">
                      <label
                        className="user-profile-input-label"
                        for="user-profile-username-input"
                      >
                        Username
                      </label>
                      <Input
                        defaultValue={userName}
                        placeholder="Username"
                        type="text"
                        onChange={(event) => {
                          setUserName(event.target.value);
                        }}
                        className="user-profile-input"
                        id="user-profile-username-input"
                      />
                    </div>
                    <div className="user-profile-input-container-column">
                      <label
                        className="user-profile-input-label"
                        htmlFor="exampleInputEmail1"
                      >
                        Email address
                      </label>
                      <Input
                        defaultValue={email}
                        placeholder="Email"
                        type="email"
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                        className="user-profile-input"
                      />
                    </div>
                  </div>
                  <div className="user-profile-input-container-row">
                    <div className="user-profile-input-container-column">
                      <label className="user-profile-input-label">
                        Display Name
                      </label>
                      <Input
                        defaultValue={displayName}
                        placeholder="Display Name"
                        type="text"
                        onChange={(event) => {
                          setDisplayName(event.target.value);
                        }}
                        className="user-profile-input"
                      />
                    </div>
                    <div className="user-profile-input-container-column">
                      <label className="user-profile-input-label">
                        Gender [M/F]
                      </label>
                      <Input
                        defaultValue={gender}
                        placeholder="Gender"
                        type="text"
                        onChange={(event) => {
                          setGender(event.target.value);
                        }}
                        className="user-profile-input"
                      />
                    </div>
                  </div>
                  <div className="user-profile-input-container-row">
                    <div className="user-profile-input-container-column">
                      <label className="user-profile-input-label">Age</label>
                      <Input
                        defaultValue={age}
                        placeholder="Age"
                        type="number"
                        onChange={(event) => {
                          setAge(event.target.value);
                        }}
                        className="user-profile-input"
                      />
                    </div>
                    <div className="user-profile-input-container-column">
                      <label className="user-profile-input-label">Region</label>
                      <Input
                        defaultValue={region}
                        placeholder="Region"
                        type="select"
                        onChange={(event) => {
                          setregion(event.target.value);
                        }}
                        className="user-profile-input"
                      >
                        {locations.map((location) => {
                          return (
                            <option
                              data-time-zone-id={location.TZ_code}
                              selected={locations.timeZoneInPC}
                              value={location.timeZoneInPC}
                            >
                              {location.timeZoneInPC}
                            </option>
                          );
                        })}
                      </Input>
                    </div>
                  </div>
                  {/* <Row className="edit-profile-rows">
                    <Col className="pr-md-1 user-profile-column-1" md="5">
                      <FormGroup>
                        <label className="user-profile-input-label">
                          Username
                        </label>
                        <Input
                          defaultValue={userName}
                          placeholder="Username"
                          type="text"
                          onChange={(event) => {
                            setUserName(event.target.value);
                          }}
                          className="user-profile-input"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1 user-profile-column-2" md="5">
                      <FormGroup>
                        <label
                          className="user-profile-input-label"
                          htmlFor="exampleInputEmail1"
                        >
                          Email address
                        </label>
                        <Input
                          defaultValue={email}
                          placeholder="Email"
                          type="email"
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                          className="user-profile-input"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="edit-profile-rows">
                    <Col className="pr-md-1 user-profile-column-1" md="5">
                      <FormGroup>
                        <label className="user-profile-input-label">
                          Display Name
                        </label>
                        <Input
                          defaultValue={displayName}
                          placeholder="Display Name"
                          type="text"
                          onChange={(event) => {
                            setDisplayName(event.target.value);
                          }}
                          className="user-profile-input"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1 user-profile-column-2" md="5">
                      <FormGroup>
                        <label className="user-profile-input-label">
                          Gender [M/F]
                        </label>
                        <Input
                          defaultValue={gender}
                          placeholder="Gender"
                          type="text"
                          onChange={(event) => {
                            setGender(event.target.value);
                          }}
                          className="user-profile-input"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="edit-profile-rows">
                    <Col className="pr-md-1 user-profile-column-1" md="5">
                      <FormGroup>
                        <label className="user-profile-input-label">Age</label>
                        <Input
                          defaultValue={age}
                          placeholder="Age"
                          type="number"
                          onChange={(event) => {
                            setAge(event.target.value);
                          }}
                          className="user-profile-input"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1 user-profile-column-2" md="5">
                      <FormGroup>
                        <label className="user-profile-input-label">
                          Region
                        </label>
                        <Input
                          defaultValue={region}
                          placeholder="Region"
                          type="select"
                          onChange={(event) => {
                            setregion(event.target.value);
                          }}
                          className="user-profile-input"
                        >
                          {locations.map((location) => {
                            return (
                              <option
                                data-time-zone-id={location.TZ_code}
                                selected={locations.timeZoneInPC}
                                value={location.timeZoneInPC}
                              >
                                {location.timeZoneInPC}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row></Row> */}

                  {/* old code */}
                  {/* <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                          placeholder="Home Address"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input defaultValue="" placeholder="City" type="text" />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          defaultValue=""
                          placeholder="Country"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input placeholder="ZIP Code" type="number" />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Col md="8">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          cols="80"
                          defaultValue="Lamborghini Mercy, Your chick she so thirsty, I'm in
                            that two seat Lambo."
                          placeholder="Here can be your description"
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                </Form>
              </CardBody>
              <CardFooter>
                {/* <Button
                  className="btn-fill"
                  color="primary"
                  type="submit"
                  onClick={updateInfo}
                >
                  Save
                </Button> */}
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            {/* <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={require("assets/img/emilyz.jpg").default}
                    />
                    <h5 className="title">Little Hearts</h5>
                  </a>
                  <p className="description">Little Hearts</p>
                </div>
                <div className="card-description">Dummy text</div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button>
                </div>
              </CardFooter>
            </Card> */}
          </Col>
        </Row>
      </div>

      {/*<option
                            data-time-zone-id="1"
                            data-gmt-adjustment="GMT-12:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT-12:00) International Date Line West" ===
                              region
                            }
                            value="(GMT-12:00) International Date Line West"
                          >
                            (GMT-12:00) International Date Line West
                          </option>
                           <option
                            data-time-zone-id="2"
                            data-gmt-adjustment="GMT-11:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT-11:00) Midway Island, Samoa" === region
                            }
                            value="(GMT-11:00) Midway Island, Samoa"
                          >
                            (GMT-11:00) Midway Island, Samoa
                          </option>
                          <option
                            data-time-zone-id="3"
                            data-gmt-adjustment="GMT-10:00"
                            data-use-daylight="0"
                            selected={"(GMT-10:00) Hawaii" === region}
                            value="(GMT-10:00) Hawaii"
                          >
                            (GMT-10:00) Hawaii
                          </option>
                          <option
                            data-time-zone-id="4"
                            data-gmt-adjustment="GMT-09:00"
                            data-use-daylight="1"
                            selected={"(GMT-09:00) Alaska" === region}
                            value="(GMT-09:00) Alaska"
                          >
                            (GMT-09:00) Alaska
                          </option>
                          <option
                            data-time-zone-id="5"
                            data-gmt-adjustment="GMT-08:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT-08:00) Pacific Time (US & Canada)" ===
                              region
                            }
                            value="(GMT-08:00) Pacific Time (US & Canada)"
                          >
                            (GMT-08:00) Pacific Time (US & Canada)
                          </option>
                          <option
                            data-time-zone-id="6"
                            data-gmt-adjustment="GMT-08:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT-08:00) Tijuana, Baja California" === region
                            }
                            value="(GMT-08:00) Tijuana, Baja California"
                          >
                            (GMT-08:00) Tijuana, Baja California
                          </option>
                          <option
                            data-time-zone-id="7"
                            data-gmt-adjustment="GMT-07:00"
                            data-use-daylight="0"
                            selected={"(GMT-07:00) Arizona" === region}
                            value="(GMT-07:00) Arizona"
                          >
                            (GMT-07:00) Arizona
                          </option>
                          <option
                            data-time-zone-id="8"
                            data-gmt-adjustment="GMT-07:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT-07:00) Chihuahua, La Paz, Mazatlan" ===
                              region
                            }
                            value="(GMT-07:00) Chihuahua, La Paz, Mazatlan"
                          >
                            (GMT-07:00) Chihuahua, La Paz, Mazatlan
                          </option>
                          <option
                            data-time-zone-id="9"
                            data-gmt-adjustment="GMT-07:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT-07:00) Mountain Time (US & Canada)" ===
                              region
                            }
                            value="(GMT-07:00) Mountain Time (US & Canada)"
                          >
                            (GMT-07:00) Mountain Time (US & Canada)
                          </option>
                          <option
                            data-time-zone-id="10"
                            data-gmt-adjustment="GMT-06:00"
                            data-use-daylight="0"
                            selected={"(GMT-06:00) Central America" === region}
                            value="(GMT-06:00) Central America"
                          >
                            (GMT-06:00) Central America
                          </option>
                          <option
                            data-time-zone-id="11"
                            data-gmt-adjustment="GMT-06:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT-06:00) Central Time (US & Canada)" ===
                              region
                            }
                            value="(GMT-06:00) Central Time (US & Canada)"
                          >
                            (GMT-06:00) Central Time (US & Canada)
                          </option>
                          <option
                            data-time-zone-id="12"
                            data-gmt-adjustment="GMT-06:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT-06:00) Guadalajara, Mexico City, Monterrey" ===
                              region
                            }
                            value="(GMT-06:00) Guadalajara, Mexico City, Monterrey"
                          >
                            (GMT-06:00) Guadalajara, Mexico City, Monterrey
                          </option>
                          <option
                            data-time-zone-id="13"
                            data-gmt-adjustment="GMT-06:00"
                            data-use-daylight="0"
                            selected={"(GMT-06:00) Saskatchewan" === region}
                            value="(GMT-06:00) Saskatchewan"
                          >
                            (GMT-06:00) Saskatchewan
                          </option>
                          <option
                            data-time-zone-id="14"
                            data-gmt-adjustment="GMT-05:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT-05:00) Bogota, Lima, Quito, Rio Branco" ===
                              region
                            }
                            value="(GMT-05:00) Bogota, Lima, Quito, Rio Branco"
                          >
                            (GMT-05:00) Bogota, Lima, Quito, Rio Branco
                          </option>
                          <option
                            data-time-zone-id="15"
                            data-gmt-adjustment="GMT-05:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT-05:00) Eastern Time (US & Canada)" ===
                              region
                            }
                            value="(GMT-05:00) Eastern Time (US & Canada)"
                          >
                            (GMT-05:00) Eastern Time (US & Canada)
                          </option>
                          <option
                            data-time-zone-id="16"
                            data-gmt-adjustment="GMT-05:00"
                            data-use-daylight="1"
                            selected={"(GMT-05:00) Indiana (East)" === region}
                            value="(GMT-05:00) Indiana (East)"
                          >
                            (GMT-05:00) Indiana (East)
                          </option>
                          <option
                            data-time-zone-id="17"
                            data-gmt-adjustment="GMT-04:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT-04:00) Atlantic Time (Canada)" === region
                            }
                            value="(GMT-04:00) Atlantic Time (Canada)"
                          >
                            (GMT-04:00) Atlantic Time (Canada)
                          </option>
                          <option
                            data-time-zone-id="18"
                            data-gmt-adjustment="GMT-04:00"
                            data-use-daylight="0"
                            selected={"(GMT-04:00) Caracas, La Paz" === region}
                            value="(GMT-04:00) Caracas, La Paz"
                          >
                            (GMT-04:00) Caracas, La Paz
                          </option>
                          <option
                            data-time-zone-id="19"
                            data-gmt-adjustment="GMT-04:00"
                            data-use-daylight="0"
                            selected={"(GMT-04:00) Manau" === region}
                            value="(GMT-04:00) Manaus"
                          >
                            (GMT-04:00) Manaus
                          </option>
                          <option
                            data-time-zone-id="20"
                            data-gmt-adjustment="GMT-04:00"
                            data-use-daylight="1"
                            selected={"(GMT-04:00) Santiago" === region}
                            value="(GMT-04:00) Santiago"
                          >
                            (GMT-04:00) Santiago
                          </option>
                          <option
                            data-time-zone-id="21"
                            data-gmt-adjustment="GMT-03:30"
                            data-use-daylight="1"
                            selected={"(GMT-03:30) Newfoundland" === region}
                            value="(GMT-03:30) Newfoundland"
                          >
                            (GMT-03:30) Newfoundland
                          </option>
                          <option
                            data-time-zone-id="22"
                            data-gmt-adjustment="GMT-03:00"
                            data-use-daylight="1"
                            selected={"(GMT-03:00) Brasilia" === region}
                            value="(GMT-03:00) Brasilia"
                          >
                            (GMT-03:00) Brasilia
                          </option>
                          <option
                            data-time-zone-id="23"
                            data-gmt-adjustment="GMT-03:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT-03:00) Buenos Aires, Georgetown" === region
                            }
                            value="(GMT-03:00) Buenos Aires, Georgetown"
                          >
                            (GMT-03:00) Buenos Aires, Georgetown
                          </option>
                          <option
                            data-time-zone-id="24"
                            data-gmt-adjustment="GMT-03:00"
                            data-use-daylight="1"
                            selected={"(GMT-03:00) Greenland" === region}
                            value="(GMT-03:00) Greenland"
                          >
                            (GMT-03:00) Greenland
                          </option>
                          <option
                            data-time-zone-id="25"
                            data-gmt-adjustment="GMT-03:00"
                            data-use-daylight="1"
                            selected={"(GMT-03:00) Montevideo" === region}
                            value="(GMT-03:00) Montevideo"
                          >
                            (GMT-03:00) Montevideo
                          </option>
                          <option
                            data-time-zone-id="26"
                            data-gmt-adjustment="GMT-02:00"
                            data-use-daylight="1"
                            selected={"(GMT-02:00) Mid-Atlantic" === region}
                            value="(GMT-02:00) Mid-Atlantic"
                          >
                            (GMT-02:00) Mid-Atlantic
                          </option>
                          <option
                            data-time-zone-id="27"
                            data-gmt-adjustment="GMT-01:00"
                            data-use-daylight="0"
                            selected={"(GMT-01:00) Cape Verde Is." === region}
                            value="(GMT-01:00) Cape Verde Is."
                          >
                            (GMT-01:00) Cape Verde Is.
                          </option>
                          <option
                            data-time-zone-id="28"
                            data-gmt-adjustment="GMT-01:00"
                            data-use-daylight="1"
                            selected={"(GMT-01:00) Azores" === region}
                            value="(GMT-01:00) Azores"
                          >
                            (GMT-01:00) Azores
                          </option>
                          <option
                            data-time-zone-id="29"
                            data-gmt-adjustment="GMT+00:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT+00:00) Casablanca, Monrovia, Reykjavik" ===
                              region
                            }
                            value="(GMT+00:00) Casablanca, Monrovia, Reykjavik"
                          >
                            (GMT+00:00) Casablanca, Monrovia, Reykjavik
                          </option>
                          <option
                            data-time-zone-id="30"
                            data-gmt-adjustment="GMT+00:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London" ===
                              region
                            }
                            value="(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London"
                          >
                            (GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh,
                            Lisbon, London
                          </option>
                          <option
                            data-time-zone-id="31"
                            data-gmt-adjustment="GMT+01:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" ===
                              region
                            }
                            value="(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
                          >
                            (GMT+01:00) Amsterdam, Berlin, Bern, Rome,
                            Stockholm, Vienna
                          </option>
                          <option
                            data-time-zone-id="32"
                            data-gmt-adjustment="GMT+01:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" ===
                              region
                            }
                            value="(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
                          >
                            (GMT+01:00) Belgrade, Bratislava, Budapest,
                            Ljubljana, Prague
                          </option>
                          <option
                            data-time-zone-id="33"
                            data-gmt-adjustment="GMT+01:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris" ===
                              region
                            }
                            value="(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"
                          >
                            (GMT+01:00) Brussels, Copenhagen, Madrid, Paris
                          </option>
                          <option
                            data-time-zone-id="34"
                            data-gmt-adjustment="GMT+01:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" ===
                              region
                            }
                            value="(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"
                          >
                            (GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb
                          </option>
                          <option
                            data-time-zone-id="35"
                            data-gmt-adjustment="GMT+01:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+01:00) West Central Africa" === region
                            }
                            value="(GMT+01:00) West Central Africa"
                          >
                            (GMT+01:00) West Central Africa
                          </option>
                          <option
                            data-time-zone-id="36"
                            data-gmt-adjustment="GMT+02:00"
                            data-use-daylight="1"
                            selected={"(GMT+02:00) Amman" === region}
                            value="(GMT+02:00) Amman"
                          >
                            (GMT+02:00) Amman
                          </option>
                          <option
                            data-time-zone-id="37"
                            data-gmt-adjustment="GMT+02:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+02:00) Athens, Bucharest, Istanbul" ===
                              region
                            }
                            value="(GMT+02:00) Athens, Bucharest, Istanbul"
                          >
                            (GMT+02:00) Athens, Bucharest, Istanbul
                          </option>
                          <option
                            data-time-zone-id="38"
                            data-gmt-adjustment="GMT+02:00"
                            data-use-daylight="1"
                            selected={"(GMT+02:00) Beirut" === region}
                            value="(GMT+02:00) Beirut"
                          >
                            (GMT+02:00) Beirut
                          </option>
                          <option
                            data-time-zone-id="39"
                            data-gmt-adjustment="GMT+02:00"
                            data-use-daylight="1"
                            selected={"(GMT+02:00) Cairo" === region}
                            value="(GMT+02:00) Cairo"
                          >
                            (GMT+02:00) Cairo
                          </option>
                          <option
                            data-time-zone-id="40"
                            data-gmt-adjustment="GMT+02:00"
                            data-use-daylight="0"
                            selected={"(GMT+02:00) Harare, Pretoria" === region}
                            value="(GMT+02:00) Harare, Pretoria"
                          >
                            (GMT+02:00) Harare, Pretoria
                          </option>
                          <option
                            data-time-zone-id="41"
                            data-gmt-adjustment="GMT+02:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" ===
                              region
                            }
                            value="(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
                          >
                            (GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn,
                            Vilnius
                          </option>
                          <option
                            data-time-zone-id="42"
                            data-gmt-adjustment="GMT+02:00"
                            data-use-daylight="1"
                            selected={"(GMT+02:00) Jerusalem" === region}
                            value="(GMT+02:00) Jerusalem"
                          >
                            (GMT+02:00) Jerusalem
                          </option>
                          <option
                            data-time-zone-id="43"
                            data-gmt-adjustment="GMT+02:00"
                            data-use-daylight="1"
                            selected={"(GMT+02:00) Minsk" === region}
                            value="2(GMT+02:00) Minsk"
                          >
                            (GMT+02:00) Minsk
                          </option>
                          <option
                            data-time-zone-id="44"
                            data-gmt-adjustment="GMT+02:00"
                            data-use-daylight="1"
                            selected={"(GMT+02:00) Windhoek" === region}
                            value="(GMT+02:00) Windhoek"
                          >
                            (GMT+02:00) Windhoek
                          </option>
                          <option
                            data-time-zone-id="45"
                            data-gmt-adjustment="GMT+03:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT+03:00) Kuwait, Riyadh, Baghdad" === region
                            }
                            value="(GMT+03:00) Kuwait, Riyadh, Baghdad"
                          >
                            (GMT+03:00) Kuwait, Riyadh, Baghdad
                          </option>
                          <option
                            data-time-zone-id="46"
                            data-gmt-adjustment="GMT+03:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+03:00) Moscow, St. Petersburg, Volgograd" ===
                              region
                            }
                            value="(GMT+03:00) Moscow, St. Petersburg, Volgograd"
                          >
                            (GMT+03:00) Moscow, St. Petersburg, Volgograd
                          </option>
                          <option
                            data-time-zone-id="47"
                            data-gmt-adjustment="GMT+03:00"
                            data-use-daylight="0"
                            selected={"(GMT+03:00) Nairobi" === region}
                            value="(GMT+03:00) Nairobi"
                          >
                            (GMT+03:00) Nairobi
                          </option>
                          <option
                            data-time-zone-id="48"
                            data-gmt-adjustment="GMT+03:00"
                            data-use-daylight="0"
                            selected={"(GMT+03:00) Tbilisi" === region}
                            value="(GMT+03:00) Tbilisi"
                          >
                            (GMT+03:00) Tbilisi
                          </option>
                          <option
                            data-time-zone-id="49"
                            data-gmt-adjustment="GMT+03:30"
                            data-use-daylight="1"
                            selected={"(GMT+03:30) Tehran" === region}
                            value="(GMT+03:30) Tehran"
                          >
                            (GMT+03:30) Tehran
                          </option>
                          <option
                            data-time-zone-id="50"
                            data-gmt-adjustment="GMT+04:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT+04:00) Abu Dhabi, Muscat" === region
                            }
                            value="(GMT+04:00) Abu Dhabi, Muscat"
                          >
                            (GMT+04:00) Abu Dhabi, Muscat
                          </option>
                          <option
                            data-time-zone-id="51"
                            data-gmt-adjustment="GMT+04:00"
                            data-use-daylight="1"
                            selected={"(GMT+04:00) Baku" === region}
                            value="(GMT+04:00) Baku"
                          >
                            (GMT+04:00) Baku
                          </option>
                          <option
                            data-time-zone-id="52"
                            data-gmt-adjustment="GMT+04:00"
                            data-use-daylight="1"
                            selected={"(GMT+04:00) Yerevan" === region}
                            value="(GMT+04:00) Yerevan"
                          >
                            (GMT+04:00) Yerevan
                          </option>
                          <option
                            data-time-zone-id="53"
                            data-gmt-adjustment="GMT+04:30"
                            data-use-daylight="0"
                            selected={"(GMT+04:30) Kabul" === region}
                            value="(GMT+04:30) Kabul"
                          >
                            (GMT+04:30) Kabul
                          </option>
                          <option
                            data-time-zone-id="54"
                            data-gmt-adjustment="GMT+05:00"
                            data-use-daylight="1"
                            selected={"(GMT+05:00) Yekaterinburg" === region}
                            value="(GMT+05:00) Yekaterinburg"
                          >
                            (GMT+05:00) Yekaterinburg
                          </option>
                          <option
                            data-time-zone-id="55"
                            data-gmt-adjustment="GMT+05:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT+05:00) Islamabad, Karachi, Tashkent" ===
                              region
                            }
                            value="(GMT+05:00) Islamabad, Karachi, Tashkent"
                          >
                            (GMT+05:00) Islamabad, Karachi, Tashkent
                          </option>
                          <option
                            data-time-zone-id="56"
                            data-gmt-adjustment="GMT+05:30"
                            data-use-daylight="0"
                            selected={
                              "(GMT+05:30) Sri Jayawardenapura" === region
                            }
                            value="(GMT+05:30) Sri Jayawardenapura"
                          >
                            (GMT+05:30) Sri Jayawardenapura
                          </option>
                          <option
                            data-time-zone-id="57"
                            data-gmt-adjustment="GMT+05:30"
                            data-use-daylight="0"
                            selected={
                              "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" ===
                              region
                            }
                            value="(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi"
                          >
                            (GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi
                          </option>
                          <option
                            data-time-zone-id="58"
                            data-gmt-adjustment="GMT+05:45"
                            data-use-daylight="0"
                            selected={"(GMT+05:45) Kathmandu" === region}
                            value="(GMT+05:45) Kathmandu"
                          >
                            (GMT+05:45) Kathmandu
                          </option>
                          <option
                            data-time-zone-id="59"
                            data-gmt-adjustment="GMT+06:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+06:00) Almaty, Novosibirsk" === region
                            }
                            value="(GMT+06:00) Almaty, Novosibirsk"
                          >
                            (GMT+06:00) Almaty, Novosibirsk
                          </option>
                          <option
                            data-time-zone-id="60"
                            data-gmt-adjustment="GMT+06:00"
                            data-use-daylight="0"
                            selected={"(GMT+06:00) Astana, Dhaka" === region}
                            value="(GMT+06:00) Astana, Dhaka"
                          >
                            (GMT+06:00) Astana, Dhaka
                          </option>
                          <option
                            data-time-zone-id="61"
                            data-gmt-adjustment="GMT+06:30"
                            data-use-daylight="0"
                            selected={"(GMT+06:30) Yangon (Rangoon)" === region}
                            value="(GMT+06:30) Yangon (Rangoon)"
                          >
                            (GMT+06:30) Yangon (Rangoon)
                          </option>
                          <option
                            data-time-zone-id="62"
                            data-gmt-adjustment="GMT+07:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT+07:00) Bangkok, Hanoi, Jakarta" === region
                            }
                            value="(GMT+07:00) Bangkok, Hanoi, Jakarta"
                          >
                            (GMT+07:00) Bangkok, Hanoi, Jakarta
                          </option>
                          <option
                            data-time-zone-id="63"
                            data-gmt-adjustment="GMT+07:00"
                            data-use-daylight="1"
                            selected={"(GMT+07:00) Krasnoyarsk" === region}
                            value="(GMT+07:00) Krasnoyarsk"
                          >
                            (GMT+07:00) Krasnoyarsk
                          </option>
                          <option
                            data-time-zone-id="64"
                            data-gmt-adjustment="GMT+08:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi" ===
                              region
                            }
                            value="(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
                          >
                            (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi
                          </option>
                          <option
                            data-time-zone-id="65"
                            data-gmt-adjustment="GMT+08:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT+08:00) Kuala Lumpur, Singapore" === region
                            }
                            value="(GMT+08:00) Kuala Lumpur, Singapore"
                          >
                            (GMT+08:00) Kuala Lumpur, Singapore
                          </option>
                          <option
                            data-time-zone-id="66"
                            data-gmt-adjustment="GMT+08:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT+08:00) Irkutsk, Ulaan Bataar" === region
                            }
                            value="(GMT+08:00) Irkutsk, Ulaan Bataar"
                          >
                            (GMT+08:00) Irkutsk, Ulaan Bataar
                          </option>
                          <option
                            data-time-zone-id="67"
                            data-gmt-adjustment="GMT+08:00"
                            data-use-daylight="0"
                            selected={"(GMT+08:00) Perth" === region}
                            value="(GMT+08:00) Perth"
                          >
                            (GMT+08:00) Perth
                          </option>
                          <option
                            data-time-zone-id="68"
                            data-gmt-adjustment="GMT+08:00"
                            data-use-daylight="0"
                            selected={"(GMT+08:00) Taipei" === region}
                            value="(GMT+08:00) Taipei"
                          >
                            (GMT+08:00) Taipei
                          </option>
                          <option
                            data-time-zone-id="69"
                            data-gmt-adjustment="GMT+09:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT+09:00) Osaka, Sapporo, Tokyo" === region
                            }
                            value="(GMT+09:00) Osaka, Sapporo, Tokyo"
                          >
                            (GMT+09:00) Osaka, Sapporo, Tokyo
                          </option>
                          <option
                            data-time-zone-id="70"
                            data-gmt-adjustment="GMT+09:00"
                            data-use-daylight="0"
                            selected={"(GMT+09:00) Seoul" === region}
                            value="(GMT+09:00) Seoul"
                          >
                            (GMT+09:00) Seoul
                          </option>
                          <option
                            data-time-zone-id="71"
                            data-gmt-adjustment="GMT+09:00"
                            data-use-daylight="1"
                            selected={"(GMT+09:00) Yakutsk" === region}
                            value="(GMT+09:00) Yakutsk"
                          >
                            (GMT+09:00) Yakutsk
                          </option>
                          <option
                            data-time-zone-id="72"
                            data-gmt-adjustment="GMT+09:30"
                            data-use-daylight="0"
                            selected={"(GMT+09:30) Adelaide" === region}
                            value="(GMT+09:30) Adelaide"
                          >
                            (GMT+09:30) Adelaide
                          </option>
                          <option
                            data-time-zone-id="73"
                            data-gmt-adjustment="GMT+09:30"
                            data-use-daylight="0"
                            selected={"(GMT+09:30) Darwin" === region}
                            value="(GMT+09:30) Darwin"
                          >
                            (GMT+09:30) Darwin
                          </option>
                          <option
                            data-time-zone-id="74"
                            data-gmt-adjustment="GMT+10:00"
                            data-use-daylight="0"
                            selected={"(GMT+10:00) Brisbane" === region}
                            value="(GMT+10:00) Brisbane"
                          >
                            (GMT+10:00) Brisbane
                          </option>
                          <option
                            data-time-zone-id="75"
                            data-gmt-adjustment="GMT+10:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+10:00) Canberra, Melbourne, Sydney" ===
                              region
                            }
                            value="(GMT+10:00) Canberra, Melbourne, Sydney"
                          >
                            (GMT+10:00) Canberra, Melbourne, Sydney
                          </option>
                          <option
                            data-time-zone-id="76"
                            data-gmt-adjustment="GMT+10:00"
                            data-use-daylight="1"
                            selected={"(GMT+10:00) Hobart" === region}
                            value="(GMT+10:00) Hobart"
                          >
                            (GMT+10:00) Hobart
                          </option>
                          <option
                            data-time-zone-id="77"
                            data-gmt-adjustment="GMT+10:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT+10:00) Guam, Port Moresby" === region
                            }
                            value="(GMT+10:00) Guam, Port Moresby"
                          >
                            (GMT+10:00) Guam, Port Moresby
                          </option>
                          <option
                            data-time-zone-id="78"
                            data-gmt-adjustment="GMT+10:00"
                            data-use-daylight="1"
                            selected={"(GMT+10:00) Vladivostok" === region}
                            value="(GMT+10:00) Vladivostok"
                          >
                            (GMT+10:00) Vladivostok
                          </option>
                          <option
                            data-time-zone-id="79"
                            data-gmt-adjustment="GMT+11:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+11:00) Magadan, Solomon Is., New Caledonia" ===
                              region
                            }
                            value="(GMT+11:00) Magadan, Solomon Is., New Caledonia"
                          >
                            (GMT+11:00) Magadan, Solomon Is., New Caledonia
                          </option>
                          <option
                            data-time-zone-id="80"
                            data-gmt-adjustment="GMT+12:00"
                            data-use-daylight="1"
                            selected={
                              "(GMT+12:00) Auckland, Wellington" === region
                            }
                            value="(GMT+12:00) Auckland, Wellington"
                          >
                            (GMT+12:00) Auckland, Wellington
                          </option>
                          <option
                            data-time-zone-id="81"
                            data-gmt-adjustment="GMT+12:00"
                            data-use-daylight="0"
                            selected={
                              "(GMT+12:00) Fiji, Kamchatka, Marshall Is." ===
                              region
                            }
                            value="(GMT+12:00) Fiji, Kamchatka, Marshall Is."
                          >
                            (GMT+12:00) Fiji, Kamchatka, Marshall Is.
                          </option>
                          <option
                            data-time-zone-id="82"
                            data-gmt-adjustment="GMT+13:00"
                            data-use-daylight="0"
                            selected={"(GMT+13:00) Nuku'alofa" === region}
                            value="(GMT+13:00) Nuku'alofa"
                          >
                            (GMT+13:00) Nuku'alofa
                          </option> */}
    </>
  );
}

export default UserProfile;
