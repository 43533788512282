/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import Chart1 from "../variables/7daysInfo";
import Chart2 from "../variables/15daysInfo";
import Chart3 from "../variables/pastHours";
import { LittleHeartsAPI } from "backend";
import {
  getContentCreatorDashboard,
  getContentCreatorData,
  getUserDashboard,
  isSignedIn,
} from "../apiCalls/apiCalls";
import { Cookies } from "react-cookie";
import { read_cookie } from "sfcookies";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from "variables/charts.js";

import "../assets/manualCSS/dashboards.css";
import Chart4 from "variables/topContents";
import CreatorLog from "./CreatorLog";
import CreatorSummary from "./CreatorSummary";
import RequestClaim from "./RequestClaim";
import History from "./History";
function CreatorDashboard(props) {
  //   const [bigChartData, setbigChartData] = React.useState("data1");
  //   const [chartDataBig, setChartDataBig] = useState([]);

  const [topContentsDisplay, setTopContentsDisplay] = useState({
    dayWiseHeartsGiven: [],
    topHeartsGivenContents: [],
  });
  const [topContents, setTopContents] = useState([]);
  const [topContentsHearts, setTopContentsHearts] = useState([]);

  const [hours, setHours] = useState(true);
  const [days7, setDays7] = useState(false);
  const [days15, setDays15] = useState(false);
  const [showContent, setShowContent] = useState(0);

  //   const setBgChartData = (name) => {
  // setbigChartData(name);
  //   };

  return (
    <>
      <div className="content">
        <h1 className="dashboardTitle"> Creator Dashboard</h1>
        <Row className="button-position">
          <Col className="dashboard-button-row">
            <button
              type="button"
              className={
                showContent === 0
                  ? "dashboard-button-active"
                  : "dashboard-button"
              }
              onClick={() => {
                setShowContent(0);
              }}
            >
              Summary
            </button>
            <button
              type="button"
              className={
                showContent === 1
                  ? "dashboard-button-active"
                  : "dashboard-button"
              }
              onClick={() => {
                setShowContent(1);
              }}
            >
              Link Content
            </button>
            {/* <button
              type="button"
              className={showContent === 2 ? "button-active" : "button"}
              onClick={() => {
                setShowContent(2);
              }}
            >
              History
            </button> */}
            {/* <button
              type="button"
              className={showContent === 3 ? "button-active" : "button"}
              onClick={() => {
                setShowContent(3);
              }}
            >
              Log
            </button> */}
          </Col>
        </Row>
        <Row className="custom-card">
          <Col lg="10" md="12" className="card-position">
            {showContent === 0 ? <CreatorSummary /> : null}
            {showContent === 1 ? <RequestClaim /> : null}
            {/* {showContent === 2 ? <History /> : null} */}
            {/* {showContent === 3 ? <CreatorLog /> : null} */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CreatorDashboard;
