import React, { useState } from "react";
import logo from "../../assets/icons/logo.png";
import aboutUsImage from "../../assets/icons/aboutUs.png";
import AboutUs from "./AboutUs";
import FAQ from "./FAQ";
const Home = () => {
  const [pageToDisplay, setPageToDisplay] = useState(1);

  return (
    <div>
      <nav id="navbar">
        <div id="navbar-header">
          <div id="navbar-logo-container">
            <img id="navbar-logo" src={logo} alt="" />
          </div>
          <div id="navbar-links-container">
            <div className="navbar-links">
              <a href="/signIn">Log In</a>
            </div>
            <div
              onClick={() => setPageToDisplay(1)}
              className={
                pageToDisplay === 1
                  ? "navbar-links navbar-link-active"
                  : "navbar-links"
              }
            >
              <div>About Us</div>
            </div>
            <div
              onClick={() => setPageToDisplay(2)}
              className={
                pageToDisplay === 2
                  ? "navbar-links navbar-link-active"
                  : "navbar-links"
              }
            >
              <div>FAQ</div>
            </div>
          </div>
        </div>
      </nav>
      {pageToDisplay === 1 ? <AboutUs /> : <FAQ />}
    </div>
  );
};

export default Home;
