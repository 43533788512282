import React, { useState } from "react";

const MyAccordion = ({ ques, ans }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className={isActive ? "faq active" : "faq"}>
      <div className="question" onClick={() => setIsActive(!isActive)}>
        <h3>{ques}</h3>
        <svg width="15" height="10" viewBox="0 0 42 25">
          <path
            d="M3 3L21 21L39 3"
            stroke="white"
            strokeWidth="7"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <div className="answer">
        <p>{ans}</p>
      </div>
    </div>
  );
};

export default MyAccordion;
