// export const LittleHeartsAPI = "http://localhost:8000/littlehearts";
// export const PaymentAPI = "http://localhost:8000";

// export const LittleHeartsAPI =
//   "https://littlehearts-developers-iraq34kuha-de.a.run.app/littlehearts";
// export const PaymentAPI =
//   "https://littlehearts-developers-iraq34kuha-de.a.run.app";

export const LittleHeartsAPI =
  "https://littleheartsphase2-backend-iraq34kuha-de.a.run.app/littlehearts";
export const PaymentAPI =
  "https://littleheartsphase2-backend-iraq34kuha-de.a.run.app";

// export const razorpayID = "rzp_live_RksOFRkJY2KVkF";
export const razorpayID = "rzp_test_NEpB6qluECPJXr";
// For ranging the count of hearts from minimum value to maximum value in the gap of minimum value
export const minLHCount = 10;
export const maxLHCount = 100;

// For ranging the gap between timeframes for redeeming hearts section for admin
export const monthGapForRedeemHearts = 2;
