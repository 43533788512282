/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import Chart1 from "../variables/7daysInfo";
import Chart2 from "../variables/15daysInfo";
import Chart3 from "../variables/pastHours";
import { LittleHeartsAPI } from "../backend";
import {
  getContentCreatorDashboard,
  getUserDashboard,
  isSignedIn,
} from "../apiCalls/apiCalls";
import { Cookies } from "react-cookie";
import { read_cookie } from "sfcookies";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from "variables/charts.js";

import "../assets/manualCSS/dashboards.css";
import Chart4 from "variables/topContents";
import Activity from "./Activity";
import AddHearts from "./AddHearts";
import Log from "./Log";
import Summary from "./Summary";
function UserDashboard(props) {
  //   const [bigChartData, setbigChartData] = React.useState("data1");
  //   const [chartDataBig, setChartDataBig] = useState([]);

  const [topContentsDisplay, setTopContentsDisplay] = useState({
    dayWiseHeartsGiven: [],
    topHeartsGivenContents: [],
  });
  const [topContents, setTopContents] = useState([]);
  const [topContentsHearts, setTopContentsHearts] = useState([]);

  const [hours, setHours] = useState(true);
  const [days7, setDays7] = useState(false);
  const [days15, setDays15] = useState(false);
  const [showContent, setShowContent] = useState(2);

  //   const setBgChartData = (name) => {
  // setbigChartData(name);
  //   };
  // const history = useHistory();
  // const { user, token } = isSignedIn();
  const user = read_cookie("userInfo");
  // const token = read_cookie("userToken");

  useEffect(async () => {
    // console.log(Cookies.get("userToken"));
    const token = await read_cookie("userToken");
    console.log(token);

    getUserDashboard(token).then((data) => {
      if (data.error) {
        console.log("Error is", data.error);
      } else {
        // setChartDataBig(data.totalHeartsPrevDays);
        setTopContentsDisplay(data);

        let topHeartsGivenContentsTemp = [];
        let topHeartsGivenContentsHeartsTemp = [];

        data.topHeartsGivenContents.forEach((object) => {
          topHeartsGivenContentsTemp.push(object.content);
          topHeartsGivenContentsHeartsTemp.push(object.hearts);
        });

        setTopContents(topHeartsGivenContentsTemp);
        setTopContentsHearts(topHeartsGivenContentsHeartsTemp);
      }
    });
  }, []);

  return (
    <>
      <div className="content">
        <h1 className="dashboardTitle"> User Dashboard</h1>
        {console.log("userrrrrrrrrrrrrrrrrrrrrr ", user)}
        <Row>
          {/*  <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Content Performances</h5>
                    <CardTitle tag="h2">Recent Performance</CardTitle>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: hours,
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => {
                          setHours(true);
                          setDays7(false);
                          setDays15(false);
                        }}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Past hours
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: days7,
                        })}
                        onClick={() => {
                          setHours(false);
                          setDays7(true);
                          setDays15(false);
                        }}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          7 Days
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: days15,
                        })}
                        onClick={() => {
                          setHours(false);
                          setDays7(false);
                          setDays15(true);
                        }}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          15 Days
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {hours && <Chart3 />}
                  {days7 === true && <Chart1 chartData={chartDataBig} />}
                  {days15 === true && <Chart2 chartData={chartDataBig} />}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row> */}
          {/*<Col lg="4">
             <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Total Shipments</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-bell-55 text-info" /> 763,215
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card> 
    </Col>*/}
          {/* this commented for graph */}
          {/* <Col lg="12">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Your top heart given contents</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-heart-2 text-primary" /> Hearts
                  Given
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Chart4 labels={topContents} data={topContentsHearts} />
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* this is commented for graph */}
          <Col lg="4">
            {/* <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Completed Tasks</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-send text-success" /> 12,100K
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample4.data}
                    options={chartExample4.options}
                  />
                </div>
              </CardBody>
            </Card> */}
          </Col>
        </Row>
        <Row className="button-position button-row">
          {/* <Col lg="6" md="12">
            <Card className="card-tasks">
              <CardHeader>
                <h6 className="title d-inline">Live updates</h6>
                {/* <p className="card-category d-inline"> today</p> */}
          {/* <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Another action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> 
              </CardHeader>
              <CardBody>
                <div className="table-full-width table-responsive">
                  <Table>
                    <tbody>
                      {/* <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">Update the Documentation</p>
                          <p className="text-muted">
                            Dwuamish Head, Seattle, WA 8:47 AM
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip636901683"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip636901683"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr> */}
          {/* <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input
                                defaultChecked
                                defaultValue=""
                                type="checkbox"
                              />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">GDPR Compliance</p>
                          <p className="text-muted">
                            The GDPR is a regulation that requires businesses to
                            protect the personal data and privacy of Europe
                            citizens for transactions that occur within EU
                            member states.
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip457194718"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip457194718"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">Solve the issues</p>
                          <p className="text-muted">
                            Fifty percent of all respondents said they would be
                            more likely to shop at a company
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip362404923"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip362404923"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">Release v2.0.0</p>
                          <p className="text-muted">
                            Ra Ave SW, Seattle, WA 98116, SUA 11:19 AM
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip818217463"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip818217463"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">Export the processed files</p>
                          <p className="text-muted">
                            The report also shows that consumers will not easily
                            forgive a company once a breach exposing their
                            personal data occurs.
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip831835125"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip831835125"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <p className="title">Arival at export process</p>
                          <p className="text-muted">
                            Capitol Hill, Seattle, WA 12:34 AM
                          </p>
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip217595172"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip217595172"
                            placement="right"
                          >
                            Edit Task
                          </UncontrolledTooltip>
                        </td>
                      </tr> 
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          <Col className="dashboard-button-row">
            {/* <button
              type="button"
              className={showContent === 0 ? "button-active" : "button"}
              onClick={() => {
                setShowContent(0);
              }}
            >
              Summary
            </button> */}
            <button
              type="button"
              className={
                showContent === 2
                  ? "dashboard-button-active"
                  : "dashboard-button"
              }
              onClick={() => {
                setShowContent(2);
              }}
            >
              Activity
            </button>
            <button
              type="button"
              className={
                showContent === 1
                  ? "dashboard-button-active"
                  : "dashboard-button"
              }
              onClick={() => {
                setShowContent(1);
              }}
            >
              Add Hearts
            </button>

            <button
              type="button"
              className={
                showContent === 3
                  ? "dashboard-button-active"
                  : "dashboard-button"
              }
              onClick={() => {
                setShowContent(3);
              }}
            >
              Log
            </button>
          </Col>
        </Row>
        <Row className="custom-card">
          <Col lg="10" md="12" className="card-position">
            {/* {showContent === 0 ? <Summary /> : null} */}
            {showContent === 1 ? (
              <AddHearts setShowContent={setShowContent} />
            ) : null}
            {showContent === 2 ? (
              <Activity user={user} topContentsDisplay={topContentsDisplay} />
            ) : null}
            {showContent === 3 ? <Log /> : null}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserDashboard;
